import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '../../button/button-st1';
import { useState } from 'react';
Banner.propTypes = {
    item: PropTypes.object,
};


function Banner(props) {
    const {item} = props;

    const [loggedIn, setloggedIn] = useState(localStorage.getItem(`_auth0`))

    useEffect(() => {
      setloggedIn(localStorage.getItem(`_auth0`))
    }, [localStorage])
    

    return (
        <div className="themes-container">
            <div className="wrap-heading flat-slider flex j-content-between">
                <div className="content">                                       	
                    <h1 className="heading">{item.title} {item.title3}</h1>
                    <p className="sub-heading text-light">{item.subtitle}</p>
                    <div className="flat-bt-slider flex style2">
                        <Button title='Explore Projects' addclass='sc-button' path='/projects' />&emsp;&emsp;
                        { loggedIn ? <Button title='Listen to Messages' addclass='sc-button btn-2' path='/audios' /> :
                        <Button title='Join Community' addclass='sc-button btn-2' path='/register' /> }
                    </div>                                 
                </div>
                <div className="image">
                    <img className="mark-slider-01 animate-up" src={item.img1} alt="" />                                   
                    <img className="absolute mark-slider-02 animate-zoom" src={item.img2} alt="" />
                    <div className="img-slider">
                        <div className="box-img flat-img flex">
                            <div className="img-1"><img src={item.img3}alt="" /></div>
                            <div className="img-2"><img src={item.img4} alt="" /> </div>                     
                        </div>
                        <div className="bg-color"></div>
                    </div>
                </div>
            </div>                                  
        </div>	
    );
}

export default Banner;