import React, { useEffect, useState } from 'react';
import '../scss/components/section.scss';
import Header from '.././components/header/header.jsx';
import PageTitle from '../components/page-title/page-title-v1';
import Flathelp from '../components/flat-help';
import Footer from '../components/footer/footer-v1';
import dataFooter from '../assets/fake-data/data-footer';
import Bottom from '../components/bottom';
import {Link, useNavigate} from 'react-router-dom';
import axios from 'axios'

function Register(props) {

    const [error, seterror] = useState()
    const navigate = useNavigate()
    const [message, setmessage] = useState()
    const [name, setname] = useState('')
    const [surname, setsurname] = useState('')
    const [email, setemail] = useState('')
    const [password, setpassword] = useState('')
    const [loading, setloading] = useState(false)

    const register = async (e)=>{
        e.preventDefault();
        setloading(true)
        seterror(null)
        setmessage(null)

        try{

            let config = {
                method: 'post',
                maxLength: 'Infinity',
                url: '/users',
                data: {
                    name, surname, email, password
                }
            }
            await axios.request(config)
            setloading(false)
            setmessage(`You have successfully registered! You can now login and start "Doing Good"`)
        }catch(err){
            seterror(err?.response ? err.response.data.message : err.message)
            setloading(false)
        }
    }

    useEffect(() => {
        document.title = `Register | DoGood`
        if(localStorage.getItem(`_auth`)){ 
            navigate(`/projects`)
        }
    }, [ localStorage ])

    return (
        <div className='wrapper-style' id='wrapper'>
            {<Header />}
            <PageTitle title='Register'  />
            <div className="tf-section flat-login flat-auctions-details">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="wrap-login">
                                <div className="box-login post">
                                    <img src={require ('../assets/images/mark/mark-header-02.png')} alt="DoGood" className="absolute mark-page3"/>
                                    <img src={require ('../assets/images/mark/mark-login-1.png')} alt="DoGood" className="absolute mark-login1"/>
                                    <img src={require ('../assets/images/mark/mark-login-2.png')} alt="DoGood" className="absolute mark-login2 animate-rotate"/>
                                    <img src={require ('../assets/images/mark/mark-login-3.png')} alt="DoGood" className="absolute mark-login3"/>
                                    <img src={require ('../assets/images/backgroup-secsion/bg-login.png')} alt="DoGood" className="absolute mark-login"/>

                                    <div className="heading-login register">
                                        <h2 className="fw-5">Join DoGood Today!</h2>
                                        <div className="flex"><p>Don’t have an account yet? </p><Link to="/login" style={{ zIndex: 999 }} className="text-p text-color-3 fw-6">Login</Link></div>
                                    </div>
                                    <form action="" onSubmit={register} id="contactform">                                
                                        <div className="form-login flat-form flex-one">
                                            <div className="info-login"> 
                                                <fieldset>
                                                    <p className='text-danger text-p'>{error ? error : ''}</p>
                                                    <p className='text-success text-p'>{message ? message : ''}</p>
                                                </fieldset>
                                                <fieldset>
                                                    <p className="title-infor-account">First Name</p>
                                                    <input type="text" id="name" onChange={e=>setname(e.target.value)} className="tb-my-input" name="name" placeholder="Enter First Name" aria-required="true" required="" />
                                                </fieldset>  
                                                <fieldset>
                                                    <p className="title-infor-account">Last Name</p>
                                                    <input type="text" id="surname" onChange={e=>setsurname(e.target.value)} className="tb-my-input" name="surname" placeholder="Enter Last Name" aria-required="true" required="" />
                                                </fieldset>  
                                                <fieldset>
                                                    <p className="title-infor-account">Email Address</p>
                                                    <input type="email" id="email" onChange={e=>setemail(e.target.value)} className="tb-my-input" name="email" placeholder="sabelo@dogood.com" aria-required="true"  required="" />
                                                </fieldset>                                                                                     
                                                <fieldset className="style-pass">
                                                    <p className="title-infor-account">Password</p>
                                                    <input type="password" name="password" onChange={e=>setpassword(e.target.value)} className="tb-my-input" id="password" placeholder="********" aria-required="true"  required="" />
                                                </fieldset>
                                                <button disabled={loading} className="submit button-login" >{ loading ? 'Please wait...' : 'Create Account'}</button>                                                                                                          
                                            </div>
                                            <div className="box-button">
                                                <div className="button-social">                            
                                                    <Link to="#" className="sc-button"><i className="fab fa-facebook-f"></i><span className="font">Continue With Facebook</span></Link>
                                                    <Link to="#" className="sc-button style-2"><i className="fab fa-google-plus-g"></i><span className="font">Continue With Google</span></Link>
                                                </div>
                                            </div>                                   
                                        </div>                            
                                    </form>     
                                </div>
                            </div>
                        </div>
                    </div>               
                </div>
            </div>
            {<Flathelp />}
            {<Footer data={dataFooter}/>}
            {<Bottom classection='widget-social-footer'/>}
        </div>
    );
}

export default Register;