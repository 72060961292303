import React, { useState } from "react";
import '../scss/components/section.scss';
import Header from '../components/header/header.jsx';
import PageTitle from '../components/page-title/page-title-v1/index.jsx';
import Flathelp from '../components/flat-help/index.jsx';
import Footer from '../components/footer/footer-v1/index.jsx';
import dataFooter from '../assets/fake-data/data-footer.js';
import Bottom from '../components/bottom/index.jsx';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation , Grid  } from 'swiper';
import { Dropdown } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import CollectionItem from '../components/collection/collection-item/index.jsx';
import dataCollection from '../assets/fake-data/data-collection.js';
import { useEffect } from "react";
import axios from "axios";

function Marketplace(props) {

    const [products, setproducts] = useState([])
    const { query } = useParams()

    const [dataDropdown] = useState(
        [
            {
                id: 1,
                title: "T-Shirts",
            },
            {
                id: 2,
                title: "Hoodies",
            },
            {
                id: 3,
                title: "Jackets",
            },
            {
                id: 4,
                title: "Accessories",
            }
        ]
    )

    const [dataDropdown2] = useState(
        [
            {
                id: 1,
                title: "On Sale",
            },
            {
                id: 2,
                title: "Clearance",
            }
        ]
    )

    const load = async ()=>{
        try{

            let response

            // if(query){
            //     response = (await axios.get(`/audios/search/${query}`)).data
            //     return setproducts(response.audios)
            // }

            response = (await axios.get(`/products`)).data
            setproducts(response.products)
        }catch(err){

        }
    }

    useEffect(() => {
        document.title = 'Marketplace | DoGood';
        load()
    }, [])

    return (
        <div className='wrapper-style' id='wrapper'>
            {<Header />}
            <PageTitle title='DoGood Marketplace' />
            <div className="tf-section flat-collection dark-style flat-auctions page">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="wrap-box explore-1 flex-one mb-27">
                                <div className="seclect-box style-1 flex text-t font">
                                <div id="item_category" className="dropdown">
                                    <Dropdown>
                                        <Dropdown.Toggle id="dropdown-basic">
                                            All categories
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu style={{ margin: 0 }}>
                                            {
                                                dataDropdown.map(data=> (
                                                    <Dropdown.Item  key={data.id} to="#">{data.title}</Dropdown.Item>
                                                ))
                                            }
                                        </Dropdown.Menu>
                                     </Dropdown>
                                    </div>
                                    {/* <div id="view" className="dropdown">
                                        <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic1">
                                            Short View
                                            </Dropdown.Toggle>

                                            {
                                                dataDropdown2.map(data=> (
                                                    <Dropdown.Item  key={data.id} to="#">{data.title}</Dropdown.Item>
                                                ))
                                            }
                                        </Dropdown>
                                    </div> */}
                                    <div id="new-items" className="dropdown">
                                        <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic2">
                                            Short View
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu style={{ margin: 0 }}>
                                            {
                                                dataDropdown2.map(data=> (
                                                    <Dropdown.Item  key={data.id} to="#">{data.title}</Dropdown.Item>
                                                ))
                                            }
                                        </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                                <div className="seclect-box style-2 box-right flex">
                                    <div className="title-item btn-selector nolink fw-6 font fs-16"><span className="text-color-3">{products.length}</span> DoGood Product(s) Found</div>
                                    <div className="widget search">
                                        <form action="#" method="get" role="search" className="search-form">
                                            <input type="search" className="search-field"
                                                placeholder="Product Name..." name="s"
                                                title="Search for" required="" />
                                            <button className="search-icon search-submit" type="submit" title="Search"></button>
                                        </form>
                                    </div> 
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12">
                        <Swiper
                            className='swiper-container carousel-blog show-shadow btn-collection2'
                            modules={[Navigation , Grid]}
                            spaceBetween={30}
                            navigation
                            grid={{
                                "rows": 2
                            }}
                            breakpoints={{
                                576: {
                                    slidesPerView: 2,
                                    slidesPerColumn: 2,
                                    spaceBetween: 30,
                                },
                                992: {
                                    slidesPerView: 3,
                                    slidesPerColumn: 2,
                                    spaceBetween: 30,
                                },
                            }}
                            >
                            {
                                products.slice(0,1).map (item => (
                                    <SwiperSlide key={item.id}>
                                        <CollectionItem key={item.id} item={item} /> 
                                    </SwiperSlide>
                                    
                                ))
                            }
                        </Swiper> 
                        {/* <div className="themesflat-pagination clearfix mtop-42 w60">
                            <ul>
                                <li><Link to="#" className="page-numbers">01</Link></li>
                                <li><Link to="#" className="page-numbers current">02</Link></li>
                                <li><Link to="#" className="page-numbers">03</Link></li>
                                <li><Link to="#" className="page-numbers">04</Link></li>
                            </ul>
                        </div>    */}
                        </div>
                    </div>
                </div>
            </div>
            {<Flathelp />}
            {<Footer data={dataFooter}/>}
            {<Bottom classection='widget-social-footer'/>}
        </div>
    );
}

export default Marketplace;