import img1 from '../images/mark/mark-slider-3.png';
import img2 from '../images/mark/mark-header-07.png';
import img3 from '../images/mark/mark-slider-1.jpg';
import img4 from '../images/mark/mark-slider-2.jpg';

import img5 from '../images/mark/mark2-slider-home2.png';
import img6 from '../images/mark/mark1-slider-home2.png';

import img7 from '../images/mark/mark2-slider-home3.png';
import img8 from '../images/mark/mark1-slider-home3.png';

const dataBanner = [
    {
        id: 1,
        classAction: 'flat-slider flex',
        title: 'Discover, Support',
        title3: ' And Start Projects',
        title4: '',
        subtitle: `Welcome to a hub of positive change! Join our community in making a difference.`,
        img1: img1,
        img2: img2,
        img3: img3,
        img4: img4,
    },
    {
        id: 2,
        classAction: 'flat-slider flex',
        title: 'Discover, Support',
        title3: 'And Start Projects.',
        title4: '',
        subtitle: `Explore, contribute, and amplify projects that matter. Together, let's create a world of impact!`,
        img1: img1,
        img2: img2,
        img3: img3,
        img4: img4,
    },
    {
        id: 3,
        classAction: 'flat-slider flex',
        title: 'Discover, Support',
        title3: 'And Start Projects.',
        title4: '',
        subtitle: 'Etiam sit amet magna risus. Pellentesque laoreet, ligula sit amet lacinia.',
        img1: img1,
        img2: img2,
        img3: img3,
        img4: img4,
    },
    {
        id: 4,
        classAction: 'flat-slider flex',
        title: 'Discover, Support',
        title3: 'And Start Projects',
        title4: '',
        subtitle: 'Etiam sit amet magna risus. Pellentesque laoreet, ligula sit amet lacinia.',
        img1: img1,
        img2: img2,
        img3: img3,
        img4: img4,
    },
]

export default dataBanner;