import React, {useEffect, useState} from 'react';
import '../scss/components/section.scss';
import Header from '../components/header/header.jsx';
import PageTitle from '../components/page-title/page-title-v1/index.jsx';
import Flathelp from '../components/flat-help/index.jsx';
import Footer from '../components/footer/footer-v1/index.jsx';
import dataFooter from '../assets/fake-data/data-footer.js';
import Bottom from '../components/bottom/index.jsx';
import ProjectItem from '../components/projects/project-item2/index.jsx';
import Category from '../components/cetagories/cetagorie-v1/index.jsx';
import dataCategory from '../assets/fake-data/data-category.js';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation , Grid  } from 'swiper';
import { Link, useParams } from "react-router-dom";
import axios from 'axios';

function Projects(props) {

    const [projects, setprojects] = useState([])
    const { category, query } = useParams()

    const load = async ()=>{
        try{

            let response

            if(query){
                response = (await axios.get(`/posts/search/${query}`)).data
                return setprojects(response.posts)
            }

            if(!category){
                response = (await axios.get(`/posts/status/active`)).data
            }else{
                response = (await axios.get(`/posts/category/${category}`)).data
            }
            setprojects(response.posts)
        }catch(err){

        }
    }

    useEffect(() => {
        document.title = 'Projects | DoGood';
        load()
    }, [ category, query ])
    

    return (
        <div className='wrapper-style' id='wrapper'>
            {<Header />}
            <PageTitle title='Projects to Support'  />
            <div className="tf-section dark-style2 ">
                <div className="container">
                    <div className="row">
                        {
                            projects.slice(0,12).map (item => (
                                <ProjectItem key={item.id} item={item} />                                 
                            ))
                        }
                    { projects.length > 12 ?
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="themesflat-pagination clearfix mtop-42 w60">
                            <ul>
                                <li><Link to="#" className="page-numbers">01</Link></li>
                                <li><Link to="#" className="page-numbers current">02</Link></li>
                                <li><Link to="#" className="page-numbers">03</Link></li>
                                <li><Link to="#" className="page-numbers">04</Link></li>
                            </ul>
                        </div>
                    </div>: null }                          
                    </div>
                </div>
            </div>
            {<Flathelp />}
            {<Footer data={dataFooter}/>}
            {<Bottom classection='widget-social-footer'/>}
        </div>
    );
}

export default Projects;