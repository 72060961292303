
import Home1 from "./Home1";
import Home2 from "./Home2";
import Home3 from "./Home3";
import Explore from "./Explore";
import LiveAuction from "./LiveAuction";
import Merchandise from "./Merchandise";
import About from "./About";
import Author from "./Author";
import Profile from "./Profile";
import EditProfile from "./EditProfile";
import ConnectWallet from "./ConnectWallet";
import Post from "./Post";
import Login from "./Login";
import Register from "./Register";
import Faq from "./Faq";
import Marketplace from "./Marketplace";
import Projects from "./Projects";
import Audios from "./Audios";
import Project from "./Project";
import Contact from "./Contact";
import Dashboard from "./Dashboard";
import Voice from "./Voice";

const routes = [
    { path: '/', component: <Home1 />},
    { path: '/home-2', component: <Home2 />},
    { path: '/home-3', component: <Home3 />},
    { path: '/dashboard', component: <Dashboard />},
    { path: '/explore', component: <Explore />},
    { path: '/liveAuction', component: <LiveAuction />},
    { path: '/shop/:id', component: <Merchandise />},
    { path: '/about', component: <About />},
    { path: '/user/:id', component: <Author />},
    { path: '/profile/:id', component: <Profile />},
    { path: '/editProfile', component: <EditProfile />},
    { path: '/connectwallet', component: <ConnectWallet />},
    { path: '/start', component: <Post />},
    { path: '/voice', component: <Voice />},
    { path: '/login', component: <Login />},
    { path: '/register', component: <Register />},
    { path: '/faq', component: <Faq />},
    { path: '/shop', component: <Marketplace />},
    { path: '/projects', component: <Projects />},
    { path: '/audios', component: <Audios />},
    { path: '/audios/:id', component: <Audios />},
    { path: '/projects/category/:category', component: <Projects />},
    { path: '/projects/search/:query', component: <Projects />},
    { path: '/projects/:id', component: <Project />},
    { path: '/contact', component: <Contact />},
  ]
  
  export default routes;