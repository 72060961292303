import React , { useEffect , useState,Fragment } from 'react';
import '../scss/components/section.scss';
import {Link, useNavigate, useParams} from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel  } from 'react-tabs';
import Header from '../components/header/header.jsx';
import PageTitle from '../components/page-title/page-title-v1/index.jsx';
import Flathelp from '../components/flat-help/index.jsx';
import Footer from '../components/footer/footer-v1/index.jsx';
import dataFooter from '../assets/fake-data/data-footer.js';
import Bottom from '../components/bottom/index.jsx';
import Liveaution from '../components/liveauctions/liveauction-v3/index.jsx';
import dataLiveaution2 from '../assets/fake-data/data-liveaution.js';

import CardModal from '../components/CardModal.jsx';

import img1 from '../assets/images/avatar/avt-01.png'
import img2 from '../assets/images/avatar/avt-04.jpg'
import img3 from '../assets/images/avatar/avt-05.jpg'
import axios from 'axios';
import toast from 'react-hot-toast';

function Merchandise(props) {

    const { id } = useParams()
    const navigate = useNavigate()
    const [product, setproduct] = useState()
    const [modalShow, setModalShow] = useState(false);

    const loadMerch = async()=>{
        try{
            let response = (await axios.get(`/products/${id}`)).data
            setproduct(response.product)
            document.title = `${response.product.title} | DoGood Marketplace`
        }catch(err){
            try{
                toast.error(err.response.data.message)
            }catch(e){
                toast.error(err.message)
            }
            navigate('/shop')
        }
    }

    useEffect(() => {
        loadMerch()
    }, [ id ]);

    return (
        <div className='wrapper-style' id='wrapper'>
            {<Header />}
            <PageTitle title={product?.title} />
            <Fragment>
                <div className="tf-section flat-auctions-details">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-12">
                                <div className="wrap-img">
                                    <div className="img-detail">
                                        <div className="media">
                                            <img src={product?.pictures[0]} alt="DoGood"/>
                                        </div>
                                        <h6 className="tags text-t">{product ? product.quantity : 0 } in stock</h6>  
                                    </div>
                                    <div className="row">
                                    {product?.pictures.map(picture=><div className="col-lg-4 col-md-12"><div className="img-detail">
                                        <div className="media">
                                            <img src={picture} alt="DoGood"/> 
                                        </div>
                                    </div>
                                    </div>)}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="post mtop-7">
                                    <h2 className="fw-5 mt-2">{product?.title}</h2>
                                    {/* <div className="meta-info mb-17">
                                        <div className="author">
                                            <div className="avatar">
                                                <img src={require ('../assets/images/avatar/avt-01.png')} alt="DoGood"/> 
                                                <div className="check">
                                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clipPath="url(#clip0_75_103)">
                                                    <circle cx="6.5" cy="5.5" r="3.5" fill="#2D333B"/>
                                                    <path d="M9.972 1.32089L6 0.00439453L2.028 1.32089C1.72874 1.4201 1.46834 1.61108 1.2838 1.86671C1.09926 2.12234 0.999959 2.42961 1 2.74489V5.99988C1 9.76188 5.6 11.8394 5.797 11.9259L5.974 12.0044L6.158 11.9434C6.356 11.8774 11 10.2884 11 5.99988V2.74489C11 2.42961 10.9007 2.12234 10.7162 1.86671C10.5317 1.61108 10.2713 1.4201 9.972 1.32089ZM6.222 6.99438C6.13459 7.08237 6.03058 7.15213 5.91599 7.19959C5.8014 7.24706 5.67853 7.27129 5.5545 7.27088H5.538C5.41145 7.26894 5.2866 7.24136 5.17101 7.18979C5.05542 7.13823 4.95149 7.06376 4.8655 6.97088L3.7125 5.77088L4.434 5.07989L5.556 6.24988L8.1465 3.65939L8.8535 4.36639L6.222 6.99438Z" fill="#231F1E"/>
                                                    </g>
                                                    <defs>
                                                    <clipPath id="clip0_75_103">
                                                    <rect width="12" height="12" fill="white"/>
                                                    </clipPath>
                                                    </defs>
                                                </svg>
                                                </div>
                                            </div>
                                            <div className="info">
                                                <h5> By<a className="text-color-3" href="author-profile.html"> Skido Tools</a> </h5>
                                                <div className="date"> In <span className="text-color-6"> @ 3d Models</span></div>                                           
                                            </div>
                                        </div>                                  
                                    </div>   */}
                                    <div className="box-price flat-price flex">                                              
                                        <div className="price flex">
                                            <div className="title-price"> P {product?.price}</div>
                                        </div> 
                                        <div className="flex">
                                            {/* <p>[5 Reviews]</p> 
                                            <div className="star">
                                                <i className="fas fa-star"></i> 
                                                <i className="fas fa-star"></i> 
                                                <i className="fas fa-star"></i> 
                                                <i className="fas fa-star"></i> 
                                                <i className="fas fa-star"></i> 
                                            </div>       */}
                                        </div>                                                  
                                    </div>
                                    <p className="texts">{product?.description}</p>
                                    <div className="button-place-bid">
                                        <Link to="#" data-toggle="modal" data-target="#popup_bid" className="sc-button btn-3" onClick={() => setModalShow(true)}><span>Buy Now</span></Link>
                                    </div>
                                    <Tabs>
                                        <TabList className="menu-tab tab-title">
                                            <Tab><h3>Reviews</h3></Tab>
                                            <Tab><h3>Info</h3></Tab>
                                            <Tab><h3>Details</h3></Tab>
                                        </TabList>
                                        <TabPanel>
                                            <div className="content-tab">
                                            {/* <ul className="bid-history-list list-1">
                                                {
                                                    dataLiveaution.map((item,index) => (
                                                    <li key={index}>
                                                        <div className="content">
                                                            <div className="meta-info flex-one style">
                                                                <div className="author">
                                                                    <div className="avatar">
                                                                        <img src={item.img} alt="DoGood"/> 
                                                                        <div className='check'>
                                                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <g clipPath="url(#clip0_75_103)">
                                                                                <circle cx="6.5" cy="5.5" r="3.5" fill="#2D333B"/>
                                                                                <path d="M9.972 1.32089L6 0.00439453L2.028 1.32089C1.72874 1.4201 1.46834 1.61108 1.2838 1.86671C1.09926 2.12234 0.999959 2.42961 1 2.74489V5.99988C1 9.76188 5.6 11.8394 5.797 11.9259L5.974 12.0044L6.158 11.9434C6.356 11.8774 11 10.2884 11 5.99988V2.74489C11 2.42961 10.9007 2.12234 10.7162 1.86671C10.5317 1.61108 10.2713 1.4201 9.972 1.32089ZM6.222 6.99438C6.13459 7.08237 6.03058 7.15213 5.91599 7.19959C5.8014 7.24706 5.67853 7.27129 5.5545 7.27088H5.538C5.41145 7.26894 5.2866 7.24136 5.17101 7.18979C5.05542 7.13823 4.95149 7.06376 4.8655 6.97088L3.7125 5.77088L4.434 5.07989L5.556 6.24988L8.1465 3.65939L8.8535 4.36639L6.222 6.99438Z" fill="#231F1E"/>
                                                                                </g>
                                                                                <defs>
                                                                                <clipPath id="clip0_75_103">
                                                                                <rect width="12" height="12" fill="white"/>
                                                                                </clipPath>
                                                                                </defs>
                                                                            </svg>
                                                                        </div>
                                                                    </div>
                                                                    <div className="info">
                                                                        <h5> By<Link className="text-color-3" to="/author">{item.author}</Link> </h5>
                                                                        <div className="date">In<span className="text-color-6"> T-Shirts</span></div>                                           
                                                                    </div>
                                                                </div> 
                                                                <div className="flat-price">                                              
                                                                    <div className="price flex">
                                                                        <div className='icon-price'>
                                                                            <svg width="17" height="28" viewBox="0 0 17 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M8.47102 0L0.0625 13.7924L8.47102 10.1023L17.0005 13.7924L8.47102 0Z" fill="#231F1E"/>
                                                                                <path d="M8.46901 9.98132L0 13.7924L8.46901 18.6318L16.9985 13.7924L8.46901 9.98132Z" fill="url(#paint0_linear_136_19)"/>
                                                                                <path d="M8.47302 27.0403L0.125 15.4257L8.47302 20.2651L16.8815 15.4257L8.47302 27.0403Z" fill="#231F1E"/>
                                                                                <defs>
                                                                                <linearGradient id="paint0_linear_136_19" x1="8.49926" y1="9.98132" x2="8.49926" y2="18.6318" gradientUnits="userSpaceOnUse">
                                                                                <stop stopColor="#4D30CA"/>
                                                                                <stop offset="1" stopColor="#231F1E"/>
                                                                                </linearGradient>
                                                                                </defs>
                                                                            </svg>
                                                                        </div>
                                                                        <div className="title-price">{item.price1}  <p className="font-2">{item.price2}</p> </div>                                                                   
                                                                    </div>                                                                                        
                                                                </div>                                 
                                                            </div>                                                     
                                                        </div>
                                                    </li>
                                                    ))
                                                }
                                                </ul> */}
                                            </div>
                                        </TabPanel>
                                         <TabPanel>
                                            <div className="content-tab">
                                                 <ul className="bid-history-list list-2">
                                                    <li>
                                                        <div className="content">
                                                            <div className="meta-info flex-one style">
                                                                <div className="author">
                                                                    <div className="avatar">
                                                                        <img src={require ('../assets/images/avatar/avt-05.jpg')} alt="DoGood"/> 
                                                                        <div className='check'>
                                                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <g clipPath="url(#clip0_75_103)">
                                                                                <circle cx="6.5" cy="5.5" r="3.5" fill="#2D333B"/>
                                                                                <path d="M9.972 1.32089L6 0.00439453L2.028 1.32089C1.72874 1.4201 1.46834 1.61108 1.2838 1.86671C1.09926 2.12234 0.999959 2.42961 1 2.74489V5.99988C1 9.76188 5.6 11.8394 5.797 11.9259L5.974 12.0044L6.158 11.9434C6.356 11.8774 11 10.2884 11 5.99988V2.74489C11 2.42961 10.9007 2.12234 10.7162 1.86671C10.5317 1.61108 10.2713 1.4201 9.972 1.32089ZM6.222 6.99438C6.13459 7.08237 6.03058 7.15213 5.91599 7.19959C5.8014 7.24706 5.67853 7.27129 5.5545 7.27088H5.538C5.41145 7.26894 5.2866 7.24136 5.17101 7.18979C5.05542 7.13823 4.95149 7.06376 4.8655 6.97088L3.7125 5.77088L4.434 5.07989L5.556 6.24988L8.1465 3.65939L8.8535 4.36639L6.222 6.99438Z" fill="#231F1E"/>
                                                                                </g>
                                                                                <defs>
                                                                                <clipPath id="clip0_75_103">
                                                                                <rect width="12" height="12" fill="white"/>
                                                                                </clipPath>
                                                                                </defs>
                                                                            </svg>
                                                                        </div>
                                                                    </div>
                                                                    <div className="info">
                                                                        <h5> By<Link className="text-color-3"> DoGood</Link> </h5>
                                                                        <div className="date"> In <span className="text-color-6"> {product?.category}</span></div>                                           
                                                                    </div>
                                                                </div>                                                                                        
                                                            </div>                                                     
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                         </TabPanel>
                                         <TabPanel>
                                            <div className="content-tab">
                                                <div className="provenance">
                                                    <p>{product?.description}</p>
                                                </div>
                                            </div>
                                        </TabPanel>
                                    </Tabs>
                                    <div className="button-social">                            
                                        <Link to="#" className="sc-button"><i className="fab fa-facebook-f"></i><span className="font">Facebook</span></Link>
                                        <Link to="#" className="sc-button style-3"><i className="fab fa-twitter"></i><span className="font">Twitter</span></Link>
                                        <Link to="#" className="sc-button style-4"><i className="fab fa-instagram"></i><span className="font">Instagram</span></Link>
                                    </div>
                                </div>                                               
                            </div>
                        </div>
                    </div>
                </div>
                <CardModal
                    product={product ? product : null}
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                />
            </Fragment>
            {<Liveaution classection='tf-section flat-auctions live-auctions style2 nft-style' data={[]}/>}
            {<Flathelp />}
            {<Footer data={dataFooter}/>}
            {<Bottom classection='widget-social-footer'/>}
        </div>
    );
}

export default Merchandise;