import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { isToday } from 'date-fns';

ChatItem.propTypes = {
    item: PropTypes.object,
    key: PropTypes.string
};


function ChatItem(props) {
    const {item} = props;
    return (
        <div onClick={props.onClick} className="message-box">
            <div className="row">
                <div className="col-lg-10 col-md-10">
                    <h5 className='textcolor-2'>{item.recipient.name} | <strong>{item.project.title}</strong></h5>
                    <p>{item.chat?.messages[item.chat?.messages.length-1].message}</p>
                </div>
                <div className="col-lg-2 col-md-2">
                    <p style={{ alignSelf: 'flex-end', float: 'right' }} className='p-right'>{ isToday(new Date(item?.chat.updated)) ? new Date(item?.chat.updated).toString().substring(16, 21) : new Date(item?.chat.updated).toString().substring(4, 21)}</p>
                </div>
            </div>
        </div>
    );
}

export default ChatItem;