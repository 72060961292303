import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import '../scss/components/section.scss';
import Header from '.././components/header/header.jsx';
import PageTitle from '../components/page-title/page-title-v1';
import Flathelp from '../components/flat-help';
import Footer from '../components/footer/footer-v1';
import dataFooter from '../assets/fake-data/data-footer';
import Bottom from '../components/bottom';
import {Link} from 'react-router-dom';
import axios from 'axios'

function Login(props) {

    const [error, seterror] = useState(null)
    const [email, setemail] = useState('')
    const [password, setpassword] = useState('')
    const [loading, setloading] = useState(false)
    const navigate = useNavigate()

    const login = async (e)=>{
        e.preventDefault();
        setloading(true)
        seterror(null)

        try{
            let config = {
                method: 'post',
                maxLength: 'Infinity',
                url: '/sessions',
                data: {
                    email, password
                }
            }
            let response = await axios.request(config)

            let { accessToken, refreshToken, user } = response.data
            localStorage.setItem('_auth', accessToken)
            localStorage.setItem('_auth0', refreshToken)
            localStorage.setItem('_$rel', btoa(JSON.stringify(user)))
            setloading(false)
            navigate("/projects", { replace: true });
        }catch(err){
            seterror(err?.response ? err.response.data.message : err.message)
            setloading(false)
        }
    }

    useEffect(() => {
        document.title = `Login | DoGood`
        if(localStorage.getItem(`_auth`)){ 
            navigate(`/projects`)
        }
    }, [ localStorage ])
    

    return (
        <div className='wrapper-style' id='wrapper'>
            {<Header />}
            <PageTitle title='Login'  />
            <div className="tf-section flat-login flat-auctions-details">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="wrap-login">
                                <div className="box-login post">
                                    <img src={require ('../assets/images/mark/mark-header-02.png')} alt="DoGood" className="absolute mark-page3"/>
                                    <img src={require ('../assets/images/mark/mark-login-1.png')} alt="DoGood" className="absolute mark-login1"/>
                                    <img src={require ('../assets/images/mark/mark-login-2.png')} alt="DoGood" className="absolute mark-login2 animate-rotate"/>
                                    <img src={require ('../assets/images/mark/mark-login-3.png')} alt="DoGood" className="absolute mark-login3"/>
                                    <img src={require ('../assets/images/backgroup-secsion/bg-login.png')} alt="DoGood" className="absolute mark-login"/>

                                    <div className="heading-login">
                                        <h2 className="fw-5">Login To DoGood</h2>
                                        <div className="flex"><p>Don’t have an account yet? </p><Link to="/register" style={{ zIndex: 999 }} className="text-p text-color-3 fw-6">Register</Link></div>
                                    </div>
                                    <form action="" onSubmit={login} id="contactform">
                                                                     
                                        <div className="form-login flat-form flex-one">
                                            <div className="info-login"> 
                                                <fieldset>
                                                    <p className='text-danger text-p'>{error ? error : ''}</p>
                                                </fieldset>  
                                                <fieldset>
                                                    <p className="title-infor-account">Email Address</p>
                                                    <input type="email" name="email" id="email" onChange={e=>setemail(e.target.value)} placeholder='sabelo@dogood.com' aria-required="true"  required />
                                                </fieldset>                                                                                       
                                                <fieldset className="style-pass">
                                                    <p className="title-infor-account">Password</p>
                                                    <input type="password" name="password" id="password" onChange={e=>setpassword(e.target.value)} placeholder="********" aria-required="true"  required />
                                                </fieldset>   
                                                <div className="row-form style-1 flex-two">
                                                    <label className="flex align">
                                                        <input type="checkbox" />
                                                        <span className="btn-checkbox flex-two"></span>
                                                        <span className="text-p text-color-7">Remember Me</span>
                                                    </label>
                                                    <Link to="#" className="forgot-pass text-p">Forgot Password?</Link>
                                                </div>
                                                <button disabled={loading} className="submit button-login" >{loading ? 'Please wait...' : 'Log In' }</button>  
                                                                                                                    
                                            </div>
                                            <div className="box-button">
                                                <div className="button-social">                            
                                                    <Link to="#" className="sc-button"><i className="fab fa-facebook-f"></i><span className="font">Continue With Facebook</span></Link>
                                                    <Link to="#" className="sc-button style-2"><i className="fab fa-google-plus-g"></i><span className="font">Continue With Google</span></Link>
                                                </div>
                                            </div>                                   
                                        </div>                            
                                    </form>   
                                </div>
                            </div>
                        </div>
                    </div>               
                </div>
            </div>
            {<Flathelp />}
            {<Footer data={dataFooter}/>}
            {<Bottom classection='widget-social-footer'/>}
        </div>
    );
}

export default Login;