import React , { useEffect, useState} from 'react';
import '../scss/components/section.scss';
import { useNavigate, useParams } from "react-router-dom";
import Header from '../components/header/header.jsx';
import PageTitle from '../components/page-title/page-title-v1/index.jsx';
import Flathelp from '../components/flat-help/index.jsx';
import Footer from '../components/footer/footer-v1/index.jsx';
import dataFooter from '../assets/fake-data/data-footer.js';
import Bottom from '../components/bottom/index.jsx';
import dataCategory from '../assets/fake-data/data-category.js';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { TwitterShareButton, FacebookShareButton, WhatsappShareButton, EmailShareButton } from "react-share";
import { Link } from "react-router-dom";
import FileBase64 from 'react-file-base64';
import toast from "react-hot-toast";
import axios from 'axios';

function Project(props) {

    const [project, setproject] = useState()
    const [loading, setloading] = useState(false)
    const [category, setcategory] = useState({
        title: `Other`,
        category: `other`
    })
    const [liking, setliking] = useState(false)
    const [user, setuser] = useState()
    const [file, setfile] = useState()
    const [auth, setauth] = useState()
    const [message, setmessage] = useState()
    const [related, setrelated] = useState([])
    const { id } = useParams()
    const navigate = useNavigate()

    const load = async ()=>{
        try{
            let response = (await axios.get(`/posts/${id}`)).data
            getRelated(response.post.category)
            setcategory(dataCategory.filter(cat=>cat.category === response.post.category)[0])
            axios.get(`/users/${response.post.user}`).then(response=>{
                setuser(response.data.user)
            })
            setproject(response.post)
        }catch(err){
            navigate(`/projects`)
        }
    }

    const getRelated = async (category)=>{
        try{
            let response = (await axios.get(`/posts/category/${category}`)).data
            setrelated(response.posts.filter(single=>single.id !== id))
        }catch(err){

        }
    }

    const support = e => {
        e.preventDefault()

        setloading(true)
        toast.promise(axios.post(`/chats`, {
            message,
            post: id
        }, {
            headers: {
                "Authorization": localStorage.getItem(`_auth`),
                "x-refresh": localStorage.getItem(`_auth0`)
            }
        }), {
            loading: `Sending your message, please wait...`,
            success: ()=>{ setloading(false); navigate(`/audios`); return `Your message has been sent to ${user?.name}!` },
            error: err => { setloading(false); return err.response ? err.response.data.message : err.message; }
        })
    }

    const update = async (e) =>{
        e.preventDefault()

        setloading(true)

        let upload = {}

        if(file){
            let response = await axios.post(`/files`, {
                name: file.name,
                type: file.type,
                file: file.base64
            }, {
                headers: {
                    "Authorization": localStorage.getItem(`_auth`),
                    "x-refresh": localStorage.getItem(`_auth0`)
                }
            })
            upload = {
                media: response.data.fileUrl,
                mediaType: file.type
            }
        }

        if(!message || message === ''){
            return
        }

        let data

        if (upload){
            data = {
                message,
                ...upload
            }
        }else {
            data = {
                message
            }
        }

        toast.promise(axios.post(`/posts/${id}`, data, {
            headers: {
                "Authorization": localStorage.getItem(`_auth`),
                "x-refresh": localStorage.getItem(`_auth0`)
            }
        }), {
            loading: `Updating, please wait...`,
            success: ()=>{ setloading(false); navigate(`/projects/${id}`); load(); return `Updated!` },
            error: err => { setloading(false); return err.response ? err.response.data.message : err.message; }
        })
    }

    const like = (e)=>{
        e.preventDefault()
        setliking(true)
        axios.put(`/posts/like/${id}`, {}, {
            headers: {
                "Authorization": localStorage.getItem(`_auth`),
                "x-refresh": localStorage.getItem(`_auth0`)
            }
        }).then(()=>{load(); setliking(false)}).catch(()=>{setliking(false)})
    }

    const selectFile = (input)=>{
        setfile(input)
    }

    useEffect(() => {
      load()
      let acc = localStorage.getItem(`_$rel`)
      if(acc){
        setauth(JSON.parse(atob(acc)))
      }
    }, [ id, localStorage ])

    return (
        <div className='wrapper-style' id='wrapper'>
            {<Header />}
            <PageTitle title={project ? project?.title : ``}  />
            { project ?
            <div className="tf-section flat-blog-details ">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <article className="post">
                                <div className="inner-content">
                                    <div className="media style">
                                        <img src={project ? project?.poster : require ('../assets/images/blog/blog-detail-1.jpg')} alt=""/>                       
                                    </div>
                                    <h2 className="title">{project ? project?.title : ``}</h2>
                                    <div className="sc-card-article">
                                        <div className="meta-info style">
                                            <div className="author">
                                                <div className="avatar">
                                                    <img src={user?.photoURL} alt="DoGood"/>
                                                </div>
                                                <div className="info">
                                                    <h5> By<Link className="text-color-3" to={`/profile/${project?.user}`}> {user?.name}</Link> </h5>
                                                    <div className="date"> Posted <span> {project?.created} ago</span></div>                                           
                                                </div>
                                            </div>                                  
                                        </div>
                                    </div>
                
                                    <p className="text">{project ? project?.content : ``}</p>

                                    <div className="infor-row flex">
                                        <div className="tags flex">
                                            <h4 className="">View Others in Same Category</h4>
                                            <div className="flex font">
                                                <Link to={`/projects/category/${category.category}`}>{category.title}</Link>
                                            </div>                                         
                                        </div> 
                                        { user ?
                                        <div className="widget-social style-1 icon-blog">
                                            <ul>
                                                {/* <li><Link to="#"><i className="far fa-thumbs-up"></i> Like</Link></li> */}
                                                <li><Link disabled={liking} to="" onClick={like}><i className={`${project?.likes.includes(auth?.uid) ? 'text-danger fas' : 'fal'} fa-heart`}> Love <span className='badge'>{project?.likes.length}</span></i></Link></li>                                  
                                            </ul>
                                        </div> : null }
                                    </div>   
                                    <div className="infor-row">
                                        <div className="tags">
                                            <h4 className="title-comment2 font-20-600">Project Updates</h4>
                                            {project.updates.length === 0 ?
                                            <p className="text">{user?.name} has not posted any update about the project yet.</p> : null }
                                        </div>
                                    </div>
                                    
                                    { project?.updates.map(update=>     
                                    <div className="row post-image" key={update.time}>
                                        <div className='row'>
                                            
                                            { update.media ? <div className='col-md-4'>
                                                { update?.mediaType.includes('image') ?
                                                <img style={{ borderRadius: 10 }} src={update?.media} alt=""/> : update?.mediaType.includes('audio') ?
                                                <AudioPlayer
                                                    src={update.media}
                                                    showJumpControls={false}
                                                    showDownloadProgress
                                                    style={{
                                                        borderRadius: 10
                                                    }}
                                                /> : update?.mediaType.includes('video') ?
                                                <video
                                                    src={update.media}
                                                    controls
                                                    style={{ borderRadius: 10 }}
                                                /> : null }
                                            </div>
                                            :  null }
                                            <div className={`col-md-${update.media ? 8 : 12} clear-fix`}>
                                                <h3 className="text-color-4">{update?.message}</h3>
                                                <p>Posted: {new Date(update?.time).toString().substring(0, 21)}</p>
                                            </div>
                                        </div>
                                    </div>) }                                                                                                      
                                 </div>  
                                 <div id="comments">
                                    <div className="title-comment font-20-400"><p>{project ? project?.supporters.length : 0 } people are supporting this project right now.</p></div>
                                    { auth ?
                                    <div id="respond" className="respond-comment">
                                        { auth.uid !== project.user && !project.supporters.includes(auth.uid) ? <>
                                        <div className="title-comment2 font-20-600">Support the project</div>
                                        <p className="text">Reach out to the owner of the project and take the conversation further on how you can contribute to this project!</p>  
                                        <form id="contactform" className="comment-form form-submit"
                                            action="" onSubmit={support} method='post' acceptCharset="utf-8"
                                            >
                                            <fieldset className="message-wrap">
                                                <textarea id="comment-message" name="message" onInput={e=>setmessage(e.target.value)} rows="3" tabIndex="4"
                                                    placeholder="Type in your message to engage with project owner"
                                                 required>{message}</textarea>
                                            </fieldset>
                                            <button disabled={loading} name="submit" type="submit" id="comment-reply"
                                                className="button btn-submit-comment"><span>{ loading ? `Sending` : `Support Project`}</span></button>
                                        </form>
                                        </>
                                        : project.supporters.includes(auth.uid) ?
                                        <p><Link to={`/dashboard`}>Continue the Conversation</Link></p>
                                        : auth.uid === project.user ? <div>
                                            <div className="title-comment2 font-20-600">Update the project</div>
                                            <p className="text">Post an update to inform project supporters.</p>  
                                            <form id="contactform" className="comment-form form-submit"
                                                action="" onSubmit={update} method='post' acceptCharset="utf-8"
                                                >
                                                <fieldset className="message-wrap">
                                                    <p>Update Media - Audio or Video or Image (Optional)</p>
                                                    <label className="uploadFile" style={{ marginBottom: 15 }}>
                                                        <FileBase64 className="inputfile form-control" multiple={false} onDone={selectFile} /> 
                                                    </label>
                                                    <textarea id="comment-message" name="message" onInput={e=>setmessage(e.target.value)} rows="3" tabIndex="4"
                                                        placeholder="Type in your message to update project supporters"
                                                    required></textarea>
                                                </fieldset>
                                                <button disabled={loading} name="submit" type="submit" id="comment-reply"
                                                    className="button btn-submit-comment"><span>{ loading ? `Updating...` : `Update Project`}</span></button>
                                            </form>
                                        </div> : null }
                                    </div> : 
                                    <div id="respond" className="respond-comment">
                                        <div className="title-comment2 font-20-600">Support the project</div>
                                        <p className="text">Login to reach out to the owner of the project and take the conversation further on how you can contribute to this project!</p>  
                                    </div>
                                    }
                                </div>
                            </article>
                        </div>
                        <div className="col-lg-4 col-md-12">
                            <aside className="side-bar">
                                <div className="inner-side-bar">                                       
                                    <div className="widget search">
                                        <div className="widget-title title-search">
                                            Search Here                                    
                                        </div>
                                        <form action="#" method="get" role="search" className="search-form">
                                            <input type="search" className="search-field"
                                                placeholder="Enter Your Keyword..." name="s"
                                                title="Search for" required="" />
                                            <button className="search-icon search-submit" type="submit" title="Search"></button>
                                        </form>
                                    </div>
                                    <div className="widget widget-categories style"> 
                                        <div className="widget-title title-list">
                                            Categories
                                        </div>                                                                                                                        
                                        <ul>
                                            {
                                                dataCategory.map((item,index) => (
                                                    <li  key={index}>
                                                        <Link to={`/projects/category/${item.category}`}>{item.title}</Link>
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                    <div className="widget style2">
                                        <div className="widget-title title-icon">
                                            Share to your socials
                                        </div>   
                                        <div className="widget-social style-2">
                                            <ul className="icon-box">
                                                <li><Link><FacebookShareButton style={{ height: 35 }} hashtag='#DoGood' content={project ? `Check out this project "${project?.title}" from DoGood` : `DoGood`} url={`https://dogood.vercel.app/project/${id}`}><i className="fab fa-facebook"></i></FacebookShareButton></Link></li>
                                                <li><Link><TwitterShareButton url={`https://dogood.vercel.app/projects/${id}`}><i className="fab fa-twitter"></i></TwitterShareButton></Link></li>
                                                <li><Link><WhatsappShareButton url={`https://dogood.vercel.app/projects/${id}`} title={project ? `Check out this project "${project?.title}" from DoGood` : `DoGood`}><i className="fab fa-whatsapp"></i></WhatsappShareButton></Link></li> 
                                                <li><Link><EmailShareButton url={`https://dogood.vercel.app/projects/${id}`} title={project ? `Check out this project "${project?.title}" from DoGood` : `DoGood`}><i className="far fa-envelope"></i></EmailShareButton></Link></li>                                                               
                                            </ul>
                                        </div>                                                                                                
                                    </div>
                                    
                                    {related.length > 0 ? <div className="widget style">
                                        <div className="widget-title title-new">
                                            Related Projects
                                        </div>
                                            {
                                                related.map((item,index) => (
                                                    <div className="sc-card-article" key={index}>
                                                        <h3><Link to={`/projects/${item.id}`}>{item.title}</Link></h3>
                                                        <div className="meta-info style">
                                                            <div className="author">
                                                                <div className="avatar">
                                                                    <img src={item.poster} style={{ height: 35, width: 35 }} alt="" />
                                                                </div>
                                                                <div className="info">
                                                                    <h5> By<Link className="text-color-3" to={`/profile/${item.auth}`}>{item.author}</Link> </h5>
                                                                    <div className="date"> Posted <span>{item.created} ago</span></div>                                           
                                                                </div>
                                                            </div>                                  
                                                        </div>
                                                    </div> 
                                                ))
                                            }
                                    </div> : null }
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            </div>
            : null }
            {<Flathelp />}
            {<Footer data={dataFooter}/>}
            {<Bottom classection='widget-social-footer'/>}
        </div>
    );
}

export default Project;