import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import App from './App';
import './App.scss';
import './scss/components/animate.scss';
import './scss/components/responsive.scss';
import './scss/components/btn-swiper.scss';
import './scss/components/popup.scss';
import ScrollToTop from "./ScrollToTop";
import axios from 'axios'
import { initializeApp } from 'firebase/app';

var firebaseConfig = {
  apiKey: "AIzaSyD_p9OLFKgqyHxvgI3HXeYdOGXP4OO_ico",
  authDomain: "dogood-dev.firebaseapp.com",
  projectId: "dogood-dev",
  storageBucket: "dogood-dev.appspot.com",
  messagingSenderId: "945298631091",
  appId: "1:945298631091:web:98a56d3aee6eb9550643c6",
  measurementId: "G-J1VXW8NML1"
};

initializeApp(firebaseConfig);

axios.defaults.baseURL = `https://1kg859neal.execute-api.us-east-1.amazonaws.com`

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.Fragment>
  <BrowserRouter>
  <ScrollToTop />
      <App />
  </BrowserRouter>
</React.Fragment>
);

