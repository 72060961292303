import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'
import { Modal } from "react-bootstrap";
import { loadScript } from "@paypal/paypal-js";
import toast from 'react-hot-toast';
import axios from 'axios';

const CardModal = (props) => {

  const [loaded, setloaded] = useState(false)
  const [quantity, setquantity] = useState(1)
  let { show, product } = props
  let navigate = useNavigate()

  const loadPaypal = async ()=>{
    let paypal;

    try {
        paypal = await loadScript({ clientId: "AQRGLDRcwae3kRCDmA9o94MpKlDSHI-cwD5Z2LkaKSFjI64rlc-sPlxYsKqZFA7O73DRaGWmWGB97ZAm" });
    } catch (error) {
        console.error("failed to load the PayPal JS SDK script", error);
    }

    if (paypal) {
        try {
            await paypal.Buttons({
              onApprove: (data)=>{
                toast.promise(axios.post(`/transactions`, {
                  title: `Purchase ${product?.title}`,
                  description: `Purchased ${quantity} ${product?.title}(s)`,
                  product: product?.id,
                  status: `approved`,
                  amount: product?.price * quantity,
                  reference: data.paymentID,
                  quantity
                }, {
                  headers: {
                    "Authorization": localStorage.getItem(`_auth`),
                    "x-refresh": localStorage.getItem(`_auth0`)
                  }
                }), {
                  loading: `Finalizing your order, this should take a moment...`,
                  success: ()=>{ props.onHide(); navigate('/dashboard?view=orders'); return `Your order has been processed successfully, item should be delivery within 48hrs (working days)`; },
                  error: err => err.response ? err.response.data.message : err.message
                }, {
                  duration: 10000,
                  style: {
                    fontSize: 16
                  }
                })
              },
              onError: err => toast.error(err.message)
            }).render("#payment-options");
            setloaded(true)
        } catch (error) {
            console.error("failed to render the PayPal Buttons", error);
        }
    }
  }

  useEffect(() => {
    if(show && !loaded){
      loadPaypal()
    }
  }, [show, loaded])
  

  return (

    <Modal
      show={props.show}
      onHide={props.onHide}
      scrollable
      // size='lg'
    >
      <Modal.Header style={{margin: 10}} closeButton/>
      <div className="modal-body">
        <h2>Buy {product?.title}</h2>
        <p className="text-center">You will charged an additional <span className="price color-popup">P 24.00</span> for delivery outside Gaborone.
        </p>
        {/* <input type="text" className="form-control" placeholder="00.00 ETH" /> */}
        <p>Enter quantity. <span className="color-popup">{product?.quantity} available</span>
        </p>
        <input type="number" min={1} max={product?.quantity} placeholder='1' onInput={e=>{setquantity(e.target.value); props.onHide(); }} value={quantity} className="form-control quantity buy-quantity" />
        <div className="hr"></div>
                                
        {/* <div className="d-flex justify-content-between">
            <p> Current Bid</p>
            <p className="text-right price color-popup"> 2.39 ETH </p>
        </div> */}
        {/* <Link to="/connectwallet" className="btn btn-primary" data-toggle="modal" data-target="#popup_bid_success"
            data-dismiss="modal" aria-label="Close"> Place a bid</Link> */}
        <div id='payment-options'></div>
      </div>
    </Modal>
      
  );
};

export default CardModal;
