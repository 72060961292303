import React, { useEffect, useState } from 'react';
import '../scss/components/section.scss';
import Header from '.././components/header/header.jsx';
import PageTitle from '../components/page-title/page-title-v1';
import Profile from '../components/layouts/profile/profile';
import dataProfile from '../assets/fake-data/data-liveaution';
import Flathelp from '../components/flat-help';
import Footer from '../components/footer/footer-v1';
import dataFooter from '../assets/fake-data/data-footer';
import Bottom from '../components/bottom';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';

function Profile1(props) {

    const { id } = useParams()
    const [user, setuser] = useState()
    const [projects, setprojects] = useState([])
    const [audios, setaudios] = useState([])
    // const [logged, setlogged] = useState(JSON.parse(atob(localStorage.getItem(`_$rel`))))

    const getprofile = ()=>{
        // logged ? setuser(logged) : setuser(null)
        // document.title = logged ? logged.name : 'Profile | DoGood'
        toast.promise(axios.get(`/profile/${id}`), {
            loading: `Loading user profile...`,
            success: displayData,
            error: handleError
        })
        
    }

    const handleError = err => {
        let error = `There was a problem loading that profile.`
        error = err.response ? err.response.data.message : err.message
        return error
    }

    const displayData = (response) => {
        setuser(response.data.user)
        setprojects(response.data.projects)
        setaudios(response.data.audios)
        let data = [...response.data.projects, ...response.data.audios]
        console.info(data)
        document.title = `${response.data.user.name} | DoGood`
        return `Done.`
    }

    useEffect(() => {
      getprofile()
    }, [ id, localStorage ])
    

    return (
        <div className='wrapper-style' id='wrapper'>
            {<Header />}
            <PageTitle title={user?.name}  />
            {<Profile user={user} projects={projects} audios={audios} />}
            {<Flathelp />}
            {<Footer data={dataFooter}/>}
            {<Bottom classection='widget-social-footer'/>}
        </div>
    );
}

export default Profile1;