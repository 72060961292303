import React, { useState, Fragment, useEffect } from "react";
import '../scss/components/section.scss';
import Header from '../components/header/header.jsx';
import PageTitle from '../components/page-title/page-title-v1/index.jsx';
import Flathelp from '../components/flat-help/index.jsx';
import Footer from '../components/footer/footer-v1/index.jsx';
import dataFooter from '../assets/fake-data/data-footer.js';
import Bottom from '../components/bottom/index.jsx';
import dataCategory from '../assets/fake-data/data-category';
import {useNavigate} from 'react-router-dom';
import img1 from '../assets/images/box-item/edit-profile-2.jpg';
import CardModal from "../components/CardModal.jsx";
import { Dropdown } from "react-bootstrap";
import FileBase64 from 'react-file-base64';
import axios from "axios";

function Post(props) {

    const [modalShow, setModalShow] = useState(false);
    const [loading, setloading] = useState(false)
    const [error, seterror] = useState()
    const [file, setfile] = useState()
    const [cover, setcover] = useState(img1)
    const [title, settitle] = useState()
    const [category, setcategory] = useState()
    const [content, setcontent] = useState()

    const navigate = useNavigate()

    const [dataDropdown] = useState(
        [
            {
                id: 1,
                title: "€10",
            },
            {
                id: 2,
                title: "€20",
            },
            {
                id: 3,
                title: "€50",
            },
            {
                id: 4,
                title: "€100",
            },
            {
                id: 5,
                title: "€150",
            },
            {
                id: 6,
                title: "€200",
            },
            {
                id: 7,
                title: "€500",
            },
        ]
    )
    const [dataDropdown2] = useState(
        [
            {
                id: 1,
                title: "Art",
            },
            {
                id: 2,
                title: "Music",
            },
            {
                id: 3,
                title: "Domain Names",
            },
            {
                id: 4,
                title: "Virtual World",
            },
            {
                id: 5,
                title: "Trading Cards",
            },
            {
                id: 6,
                title: "Sports",
            },
            {
                id: 7,
                title: "Utility",
            },
        ]
    )

    const post = async(e)=>{
        e.preventDefault();

        if(cover !== img1 && title && cover){

            let poster = null

            setloading(true)

            try{
                let response = await axios.post(`/files`, {
                    name: file.name,
                    type: file.type,
                    file: file.base64
                }, {
                    headers: {
                        "Authorization": localStorage.getItem(`_auth`),
                        "x-refresh": localStorage.getItem(`_auth0`)
                    }
                })
                poster = response.data.fileUrl
            }catch(err){
                setloading(false)
                try{
                    seterror(err.response.data.message)
                }catch(e){
                    seterror(err.message)
                }
                return err
            }

            let post = {
                title,
                content,
                category,
                poster
            }

            axios.post(`/posts`, post, {
                headers: {
                    "Authorization": localStorage.getItem(`_auth`),
                    "x-refresh": localStorage.getItem(`_auth0`)
                }
            }).then(response=>{
                setloading(false)
                navigate(`/projects/${response.data.post}`)
            }).catch(err=>{
                setloading(false)
                try{
                    seterror(err.response.data.message)
                }catch(e){
                    seterror(err.message)
                }
            })
        }
    }

    const upload = (file)=>{
        setfile(file)
        setcover(file.base64)
    }
    
    useEffect(() => {
        document.title = 'Start Project | DoGood';
        if(localStorage.getItem(`_auth`) === null){
            navigate(`/login`)
        }
    }, [localStorage])
    

    return (
        <div className='wrapper-style' id='wrapper'>
            {<Header />}
            <PageTitle title='Start A Project' />
            <Fragment>
            <div className="tf-section flat-create-item flat-edit-profile flat-auctions-details flat-explore flat-auctions">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12">
                            <div className="form-upload-profile post"> 
                                <h3 className="title-one fw-6">Choice Your Cover Image</h3> 
                                <div className="option-profile flex">                                 
                                    <form action="#">
                                        <label className="uploadFile">   
                                            <FileBase64 className="inputfile form-control" multiple={false} onDone={upload} />                                    
                                            {/* <input type="file" className="inputfile form-control" id="upload-img2" name="file" /> */}
                                        </label>
                                    </form> 
                                    <div className="img-box"> 
                                        <div className="media">
                                            <img className="avatar" id="editimg" src={cover} alt="" />   
                                        </div>                                                                                                                                       
                                    </div>
                                </div> 
                                
                                <h3 className="title-two fw-6">Project Details</h3> 
                                <form action="#" onSubmit={post} className="form-profile">                     
                                    <div className="form-infor-profile flat-form">
                                        <div className="form-infor flex">
                                            <div className="info-title info-account">                              
                                                <fieldset>
                                                    <p className="title-infor-account">Title</p>
                                                    <input type="text" onInput={e=>settitle(e.target.value)} placeholder="Enter your Project Name" required />
                                                </fieldset>                                                                                                                   
                                            </div>
                                            <div className="info-stock info-social">                                 
                                                <fieldset>
                                                    <p className="title-infor-account">Category</p>
                                                    <Dropdown>
                                                        <Dropdown.Toggle id="dropdown-basic-1" className="form-control" style={{ padding: 12 }}>
                                                            { category ? dataCategory.filter(cat=>cat.category == category)[0].title : 'Select Project Category'}
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu style={{ margin: 0 }}>
                                                            {
                                                                dataCategory.map(data=> (
                                                                    <Dropdown.Item  key={data.id} onClick={()=>setcategory(data.category)}>{data.title}</Dropdown.Item>
                                                                ))
                                                            }
                                                        </Dropdown.Menu>
                                                    </Dropdown>                                    
                                                </fieldset>
                                            </div>  
                                        </div> 
                                        <fieldset className="message">
                                            <p className="title-infor-account">Description (What is this project about? How can people assist?)</p>
                                            <textarea tabIndex="4" rows="10" onInput={e=>setcontent(e.target.value)} required></textarea>
                                        </fieldset>                                                              
                                    </div>

                                    <p className="text-danger">{ error ? error : ""}</p>
                                
                                    <button disabled={loading} className="tf-button-submit mg-t-15" type="submit">
                                        {loading ? 'Please wait...' : 'Start Project'}
                                    </button>
                                        
                                </form>              
                            </div> 
                        </div>     
                    </div>           
                </div>
            </div>
            </Fragment>
            <CardModal show={modalShow} onHide={() => setModalShow(false)} />
            {<Flathelp />}
            {<Footer data={dataFooter}/>}
            {<Bottom classNameection='widget-social-footer'/>}
        </div>
    );
}

export default Post;