import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import routes from './pages';
import toast, { Toaster } from 'react-hot-toast';
import '../src/assets/fonts/font-awesome.css'
// import { Analytics } from '@vercel/analytics/react';
import { getMessaging, getToken, isSupported, onMessage } from "firebase/messaging";
import { getApp } from 'firebase/app';
import axios from 'axios';

function App() {

  const messaging = getMessaging(getApp());
  let unsubscribe

  const retrieveToken = async(setTokenFound) => {
    let supported = await isSupported()
    if(!supported){
      return
    }
    return getToken(messaging).then((currentToken) => {
      console.log(currentToken)

      if (currentToken) {
        axios.put(`/push`, {
          token: currentToken
        }, {
          headers: {
            "Authorization": localStorage.getItem(`_auth`),
            "x-refresh": localStorage.getItem(`_auth0`)
          }
        }).then(res=>{}).catch(err=>{})
      } else {
        toast('Hey there! 🌟 Want to stay in the loop with the latest updates? Enable push notifications to never miss a beat! Just a quick tap to keep the awesome content coming your way. Thanks for being part of our community! 📲✨', {
          style: {
            fontSize: 16,
            width: 500
          },
          duration: 10000
        })
        Notification.requestPermission()
        setTokenFound(false);
      }
    }).catch((err) => {
      console.log(err)
      // toast.error(err.message)
      // toast('Hey there! 🌟 Want to stay in the loop with the latest updates? Enable push notifications to never miss a beat! Just a quick tap to keep the awesome content coming your way. Thanks for being part of our community! 📲✨', {
      //   style: {
      //     fontSize: 16,
      //     width: 500
      //   },
      //   duration: 10000
      // })
    });
  }
  
  const listenForMessages = ()=>{
    onMessage(messaging, message => {
      console.log(message)
      if(message){
        toast(message.notification.body, {
          duration: 5000,
          style: {
            fontSize: 16
          }
        })
      }
    })
  }

  const listen = async()=>{
    if(!localStorage.getItem(`_auth0`)){
      return
    }
    
    Notification.requestPermission().then(async permission=>{
      if(permission === 'granted'){
        await retrieveToken()
      }
    })

  }

  useEffect(() => {
    listen()
    listenForMessages()
    // return unsubscribe()
  }, [ localStorage ])
  

  return (
    <div className="body header-fixed">
    <>
      <Routes>
        {
          routes.map((data,idx) => (
            <Route key={idx} path={data.path} element={data.component} exact />
          ))
        }
      </Routes>
      {/* <Analytics/> */}
      <Toaster/>
    </>
    </div>
  );
}

export default App;
