import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import axios from 'axios';

Project.propTypes = {
    item: PropTypes.object,
    owner: PropTypes.bool
};


function Project(props) {
    const {item} = props;

    const [user, setuser] = useState()

    useEffect(() => {
      axios.get(`/users/${item.user}`).then(response=>{
        setuser(response.data.user)
      })
    }, [item])

    return (
        <div className="col-lg-4 col-md-4 col-sm-12">
            <div className="fl-blog fl-item2">
                <article className="sc-card-article blog-article">
                
                    <div className={`card-media ${item?.classAction}`}>
                        <Link to={`/projects/${item?.id}`}><img className="img-item" style={{ height: 250 }} src={item?.poster} alt="" /></Link>
                        </div>    
                    <div className="post">
                        <div className="text-article">
                            <h3><Link to={`/projects/${item?.id}`}>{item?.title}</Link></h3>
                            <p>{item?.content.substring(0,100)}...</p>
                        </div>
                        <div className="meta-info style">
                            { !props.owner ?
                            <div className="author">
                                <div className="avatar">
                                    <img src={user?.photoURL} alt="" />
                                </div>
                                <div className="info">
                                    <h5> By<Link className="text-color-3" to={`/profile/${item?.user}`}>{user?.name}</Link> </h5>
                                    <div className="date"> Posted <span className="text-color-6">{item?.created} ago</span></div>                                           
                                </div>
                            </div>  
                            : 
                            <div>
                                <span>{item.supporters.length} supporters &emsp;</span>
                                <button className='btn-sm btn-danger'>Delete Project</button>
                            </div>   
                            }                                
                        </div>
                    </div>                                                                                                                
                </article>
            </div>
        </div>
    );
}

export default Project;