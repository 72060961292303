import React, {useEffect, useState} from 'react';
import '../scss/components/section.scss';
import Header from '../components/header/header.jsx';
import PageTitle from '../components/page-title/page-title-v1/index.jsx';
import Flathelp from '../components/flat-help/index.jsx';
import ProjectItem from '../components/projects/project-item/index.jsx';
import Footer from '../components/footer/footer-v1/index.jsx';
import dataFooter from '../assets/fake-data/data-footer.js';
import Bottom from '../components/bottom/index.jsx';
import { Tab, Tabs, TabList, TabPanel  } from 'react-tabs';
import toast from 'react-hot-toast';
import axios from 'axios';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import ChatItem from '../components/chat/chat-item/index.jsx';
import { isToday } from 'date-fns';

function Dashboard(props) {

    const [projects, setprojects] = useState([])
    const [audios, setaudios] = useState([])
    const [chats, setchats] = useState([])
    const [chat, setchat] = useState()
    const [message, setmessage] = useState()
    const [tab, settab] = useState(0)
    const [user, setuser] = useState()
    const [transactions, settransactions] = useState([])
    const navigate = useNavigate()

    const tabs = [
        {
            id: 1,
            title: `Project Chats`
        },
        {
            id: 2,
            title: `My Projects`
        },
        {
            id: 3,
            title: `My Audio Messages`
        },
        {
            id: 4,
            title: `Order History`
        }
    ]

    function useQuery() {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    let query = useQuery()

    const getProjects = ()=>{
        //get my projects
        axios.get(`/ownposts`, {
            headers: {
                "Authorization": localStorage.getItem(`_auth`),
                "x-refresh": localStorage.getItem(`_auth0`)
            }
        }).then(response=>{
            setprojects(response.data.posts)
        }).catch(err=>{ })
    }

    const getAudios = () => {
        // get my audios
        axios.get(`/ownaudios`, {
            headers: {
                "Authorization": localStorage.getItem(`_auth`),
                "x-refresh": localStorage.getItem(`_auth0`)
            }
        }).then(response=>{
            setaudios(response.data.audios)
        }).catch(err=>{})
    }

    const getTransactions = () => {
        // get my audios
        axios.get(`/transactions`, {
            headers: {
                "Authorization": localStorage.getItem(`_auth`),
                "x-refresh": localStorage.getItem(`_auth0`)
            }
        }).then(response=>{
            settransactions(response.data.transactions)
        }).catch(err=>{})
    }

    const getChats = ()=>{
        axios.get(`/chats`, {
            headers: {
                "Authorization": localStorage.getItem(`_auth`),
                "x-refresh": localStorage.getItem(`_auth0`)
            }
        }).then(res=>{
            setchats(res.data.chats)
        }).catch(err=>{
            try{
                toast.error(err.response.data.message)
                console.info(err.response.data.message)
            }catch(e){
                toast.error(err.message)
            }
        })
    }

    const deleteProject = (id)=>{
        toast("Deleting project, please wait...")
        axios.delete(`/audios/${id}`, {
            headers: {
                "Authorization": localStorage.getItem(`_auth`),
                "x-refresh": localStorage.getItem(`_auth0`)
            }
        }).then(response=>{
            getProjects()
            toast.success("Project deleted successfully.")

        }).catch(err=>{
            try{
                toast.error(err.response.data)
            }catch(e){}
        })
    }

    const deleteAudio = (id)=>{
        toast.promise(axios.delete(`/audios/${id}`, {
            headers: {
                "Authorization": localStorage.getItem(`_auth`),
                "x-refresh": localStorage.getItem(`_auth0`)
            }
        }),{
            loading: `Deleteing the audio message, please wait...`,
            success: ()=>{ getProjects(); return `The audio message to the world has been successfully deleted`},
            error: err => err.response.data.message
        })
    }

    const loadChat = (id)=>{
        toast.promise(axios.get(`/chats/${id}`, {
            headers: {
                "Authorization": localStorage.getItem(`_auth`),
                "x-refresh": localStorage.getItem(`_auth0`)
            }
        }),{
            loading: `Updating chat messages...`,
            success: (res)=>{ viewChat(res.data) },
            error: err => err?.response ? err.response.data.message : err.message
        })
    }

    const sendMessage = (e)=>{
        e.preventDefault();
        if(!message || message === ``){
            return
        }

        let msg = message
        setmessage('')

        axios.post(`/chats/${chat.chat.id}`, {
            message: msg
        }, {
            headers: {
                "Authorization": localStorage.getItem(`_auth`),
                "x-refresh": localStorage.getItem(`_auth0`)
            }
        }).then(res=>{
            loadChat(chat.chat.id)
        }).catch(err=>{
            toast.error(err?.response ? err.response.data.message : err.message)
        })
    }

    const viewChat = (c)=>{
        setchat(c)
        setTimeout(() => {
            var objDiv = document.getElementById("chat-body");
            objDiv.scrollTop = objDiv.scrollHeight;
        }, 300);
    }

    useEffect(() => {
        document.title = 'Dashboard | DoGood';

        let auth = localStorage.getItem(`_$rel`)

        if(auth){
            setuser(JSON.parse(atob(auth)))
        }

        if(localStorage.getItem(`_auth`) === null){
            return navigate(`/login`)
        }

        let view = query.get("view")

        if(view){
            view === `orders` ? settab(3) : settab(0)
        }

        getProjects()
        getAudios()
        getTransactions()
        getChats()

    }, [ query, localStorage ])
    

    return (
        <div className='wrapper-style' id='wrapper'>
            <Header />
            <PageTitle title='My Dashboard'  />
            <div className="flat-about2">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 dashboard">
                            <Tabs selectedIndex={tab} onSelect={settab}>
                                <TabList className="menu-tab tab-title">
                                    {
                                        tabs.map(data=> (
                                            <Tab key={data.id} >
                                                <h3 className="inner">{data.title}</h3>
                                            </Tab>
                                        ))
                                    }
                                </TabList>
                                <div style={{ paddingTop: 30 }}/>
                                <TabPanel>
                                    <div className="">
                                        { !chat ? <div className='row'>
                                        {
                                            chats.length === 0 ?
                                            <div>
                                                <p>You are not supporting any projects on the platform yet.</p> 
                                                <Link className='sc-button style-2 btn-5' to={`/projects`}>Support a Project</Link>
                                            </div>
                                            : null
                                        }
                                        { chats.map(single =>{
                                            return <div key={single.chat.id}><ChatItem item={single} onClick={()=>viewChat(single)} /></div>
                                        }) }
                                        </div>
                                        :
                                        <div className='row'>
                                            {/* <div className='col-md-2'></div> */}
                                            <div className='col-md-12'>
                                                <div className='chatBox'>
                                                    <div className='chatBox-Header'>
                                                        <div style={{
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            flex: 1,
                                                            alignItems: 'center'
                                                        }}>
                                                            <div style={{ marginRight: 15 }}>
                                                                <Link style={{ fontSize: 20, alignSelf: 'center', padding: 15 }} onClick={()=>setchat(null)}><i className="fal fa-chevron-left"></i></Link>
                                                            </div>
                                                            <div>
                                                                <Link to={`/profile/${chat.recipient.uid}`}><h3 className='text-color-1'>{chat.recipient.name}</h3></Link>
                                                                <Link to={`/projects/${chat.project.id}`}><p className='text-color-7'>{chat.project.title}</p></Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='chatBox-Body' id='chat-body'>
                                                        {
                                                            chat.chat?.messages.map((message, index)=><div key={index} style={{ backgroundColor: 'rgba(122, 122, 122, 0.1)', borderRadius: 15, padding: 10, margin: 15, width: '65%', float: message.user === user.uid ? 'right' : 'left' }}>
                                                                <p className='text-p text-color-1'>
                                                                    {message.message}<br/>
                                                                    <div style={{ float: 'right' }}><small>{ isToday(new Date(message.time)) ? new Date(message.time).toString().substring(16, 21) : new Date(message.time).toString().substring(0, 21)}</small></div>
                                                                </p>
                                                            </div>)
                                                        }
                                                    </div>
                                                    <div className='chatBox-Footer'>
                                                        <div className='row'>
                                                            <div className='widget-subcribe'>
                                                                <div className="form-subcribe">
                                                                    <form id="subscribe-form" autoComplete='off' action="" onSubmit={sendMessage} className="form-submit">
                                                                        <input name="email" value={message} onInput={e=>setmessage(e.target.value)} className="email" type="text" placeholder="Type in your messsage..." required=""/>
                                                                        <button id="submit" name="submit" type="submit"><i className="fal fa-paper-plane"></i></button>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> 
                                            </div>
                                            {/* <div className='col-md-2'></div> */}
                                        </div>
                                        }
                                    </div>
                                </TabPanel>
                                <TabPanel>
                                    <div className="provenance">
                                        <div className='row'>
                                        {
                                            projects.length === 0 ?
                                            <div>
                                                <p>You have not started/created any projects on the platform yet.</p> 
                                                <Link className='sc-button style-2 btn-5' to={`/start`}>Start Project</Link>
                                            </div>
                                            : null
                                        }
                                        {
                                        projects.map (item => (
                                            <ProjectItem key={item.id} owner={true} item={item}/>
                                        ))
                                        }
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel>
                                    <div className="provenance">
                                        <div className='row'>
                                        {
                                            audios.length === 0 ?
                                            <div>
                                                <p>You have not posted or shared any messages to the world on the platform yet.</p> 
                                                <Link className='sc-button style-2 btn-5' to={`/voice`}>Send Message to the World</Link>
                                            </div>
                                            : null
                                        }
                                        {
                                        audios.map (item => (<div className='col-md-4' key={item.id}>
                                                <div className="fl-blog fl-item2">
                                                    <article className="sc-card-article blog-article">   
                                                        <div className="post">
                                                            <div className="text-article">
                                                                <h3><Link to={`/audios#${item.id}`}>{item.title}</Link></h3>
                                                                <button onClick={()=>deleteAudio(item.id)} className='btn btn-danger'>Delete Message</button>
                                                            </div>
                                                            <div className="meta-info style">
                                                                <div className="author">
                                                                    <div className="info">
                                                                        {/* <h5> By<Link className="text-color-3" to={`/profile/${item.user}`}>{user?.name}</Link> </h5> */}
                                                                        <div className="date"> Posted <span className="text-color-6">{item.created} ago</span></div>                                           
                                                                    </div>
                                                                </div>                                  
                                                            </div>
                                                        </div>                                                                                                                
                                                    </article>
                                                </div>
                                            </div>))
                                        }
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel>
                                        { transactions.length === 0 ? <div className="provenance">
                                            <p>You have not made any purchases from the marketplace.</p>
                                            <Link className='sc-button style-2 btn-5' to={`/shop`}>Go to Marketplace</Link>
                                        </div> : null }
                                        <ul className="bid-history-list list-1">
                                        {
                                            transactions.map((item,index) => (
                                            <li key={index}>
                                                <div className="content">
                                                    <div className="meta-info flex-one style">
                                                        <div className="author">
                                                            <div className="info">
                                                                <h5><Link className="text-color-3" to={`/shop/${item.product}`}>{item.title}</Link> </h5>
                                                                <div className="date"><span className="text-color-6">{item.description} on <strong>{new Date(item.created).toString().substring(0, 21)}</strong></span></div>                                           
                                                            </div>
                                                        </div> 
                                                        <div className="flat-price">                                              
                                                            <div className="price flex">
                                                                <div className='icon-price'>
                                                                    P
                                                                </div>
                                                                <div className="title-price">{item.amount}  <p className="font-2">{item.price2}</p> </div>                                                                   
                                                            </div>                                                                                        
                                                        </div>                                 
                                                    </div>                                                     
                                                </div>
                                            </li>
                                            ))
                                        }
                                        </ul>
                                </TabPanel>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
            {<Flathelp />}
            {<Footer data={dataFooter}/>}
            {<Bottom classection='widget-social-footer'/>}
        </div>
    );
}

export default Dashboard;