const menus = [
    {
        id: 1,
        name: `Home`,
        links: `/`
    },
    {
        id: 2,
        name: `About`,
        links: `/about`
    },
    {
        id: 3,
        name: 'Projects',
        links: '#',
        namesub: [
            {
                id: 1,
                sub: 'Projects',
                links: '/projects'
            },
            {
                id: 2,
                sub: 'Messages to the World',
                links: '/audios'
            }
        ]
    },
    {
        id: 4,
        name: `Marketplace`,
        links: `/shop`
    },
    {
        id: 5,
        name: `Contact`,
        links: `/contact`
    },
    {
        id: 6,
        name: `Login`,
        links: `/login`
    }
]

export default menus;