import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
Category.propTypes = {
    item: PropTypes.object,
};


function Category(props) {
    const {item} = props;

    return (
        <div className="col-lg-2 col-md-4">
            <div className="wrap-cetagories">
                <div className="img-cetagories">
                    <img  src={item.img} alt="" />
                </div>
                <div className="content flex">
                    <div className="icon-cetagories">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.3367 1.53499C10.215 0.769399 10.7377 0.0414187 11.5123 0.00991327C12.8589 -0.0448597 14.2085 0.127479 15.5039 0.522947C17.248 1.05541 18.8478 1.97836 20.1818 3.2217C21.5158 4.46503 22.5488 5.99603 23.2025 7.69841C23.688 8.96278 23.9548 10.297 23.9947 11.6441C24.0177 12.419 23.3283 12.9916 22.556 12.924C21.7838 12.8564 21.2238 12.1729 21.1731 11.3994C21.1129 10.4792 20.9143 9.57057 20.5818 8.70471C20.0811 7.40059 19.2897 6.22774 18.2677 5.27527C17.2458 4.3228 16.0203 3.61577 14.6842 3.20786C13.7971 2.93704 12.8768 2.80282 11.9546 2.80737C11.1795 2.81119 10.4583 2.30058 10.3367 1.53499Z" fill="#231F1E"/>
                            <path d="M7.4976 0.876674C5.66836 1.6171 4.04995 2.79722 2.78574 4.31252C1.52152 5.82781 0.650525 7.6315 0.249893 9.56382C-0.15074 11.4961 -0.0686388 13.4974 0.488923 15.3904C1.04648 17.2835 2.0623 19.0097 3.44638 20.4164C4.83046 21.823 6.5401 22.8666 8.42384 23.4547C10.3076 24.0428 12.3073 24.1573 14.2458 23.788C16.1844 23.4186 18.0019 22.5769 19.5375 21.3374C21.073 20.0978 22.2791 18.4987 23.0491 16.6817L22.3676 16.393C21.6452 18.0979 20.5134 19.5984 19.0726 20.7615C17.6317 21.9246 15.9263 22.7144 14.1073 23.0609C12.2883 23.4075 10.412 23.3001 8.6444 22.7483C6.87684 22.1964 5.27265 21.2172 3.97393 19.8973C2.67521 18.5774 1.72205 16.9576 1.19887 15.1813C0.675697 13.4051 0.59866 11.5272 0.974583 9.71407C1.35051 7.90093 2.16779 6.20848 3.35403 4.78665C4.54027 3.36481 6.05886 2.25747 7.77529 1.56271L7.4976 0.876674Z" fill="#231F1E"/>
                        </svg>
                    </div>
                    <div className="title-cetagories">
                        <h3 className="fw-4"><Link to="/explore">{item.title}</Link></h3>
                        <p className="font-2">{item.desc}</p>
                    </div>
                </div>
            </div>
        </div>    
    );
}

export default Category;