import React, { useState, Fragment, useEffect } from "react";
import '../scss/components/section.scss';
import Header from '../components/header/header.jsx';
import PageTitle from '../components/page-title/page-title-v1/index.jsx';
import Flathelp from '../components/flat-help/index.jsx';
import Footer from '../components/footer/footer-v1/index.jsx';
import dataFooter from '../assets/fake-data/data-footer.js';
import Bottom from '../components/bottom/index.jsx';
import { AudioRecorder } from 'react-audio-voice-recorder';
import {useNavigate} from 'react-router-dom';
import ReactAudioPlayer from 'react-audio-player';
import img1 from '../assets/images/box-item/edit-profile-2.jpg';
import CardModal from "../components/CardModal.jsx";
import { Dropdown } from "react-bootstrap";
import FileBase64 from 'react-file-base64';
import axios from "axios";
import toast from "react-hot-toast";

function Voice(props) {

    const [modalShow, setModalShow] = useState(false);
    const [loading, setloading] = useState(false)
    const [recording, setrecording] = useState()
    const [error, seterror] = useState()
    const [file, setfile] = useState()
    const [title, settitle] = useState()
    const [content, setcontent] = useState()

    const navigate = useNavigate()

    const [dataDropdown] = useState(
        [
            {
                id: 1,
                title: "€10",
            },
            {
                id: 2,
                title: "€20",
            },
            {
                id: 3,
                title: "€50",
            },
            {
                id: 4,
                title: "€100",
            },
            {
                id: 5,
                title: "€150",
            },
            {
                id: 6,
                title: "€200",
            },
            {
                id: 7,
                title: "€500",
            },
        ]
    )
    const [dataDropdown2] = useState(
        [
            {
                id: 1,
                title: "Art",
            },
            {
                id: 2,
                title: "Music",
            },
            {
                id: 3,
                title: "Domain Names",
            },
            {
                id: 4,
                title: "Virtual World",
            },
            {
                id: 5,
                title: "Trading Cards",
            },
            {
                id: 6,
                title: "Sports",
            },
            {
                id: 7,
                title: "Utility",
            },
        ]
    )

    const post = async(e)=>{
        e.preventDefault();

        if(title && recording){

            let audio = null

            setloading(true)

            try{
                let base64 = await blobToBase64(recording.blob)
                let response = await axios.post(`/files`, {
                    name: `VN${new Date().getTime()}`,
                    type: "webm",
                    file: base64
                }, {
                    headers: {
                        "Authorization": localStorage.getItem(`_auth`),
                        "x-refresh": localStorage.getItem(`_auth0`)
                    }
                })
                audio = response.data.fileUrl
            }catch(err){
                setloading(false)
                try{
                    seterror(err.response.data.message)
                    toast.error(err.response.data.message)
                }catch(e){
                    seterror(err.message)
                    toast.error(err.message)
                }
                return
            }

            let post = {
                title,
                content: audio,
            }

            toast.promise(axios.post(`/audios`, post, {
                headers: {
                    "Authorization": localStorage.getItem(`_auth`),
                    "x-refresh": localStorage.getItem(`_auth0`)
                }
            }), {
                loading: `Finishing up...`,
                success: ()=>{ setloading(false); navigate(`/audios`); return `Your message has been sent to the WORLD!` },
                error: err => { setloading(false); return err.response ? err.response.data.message : err.message; }
            })
            // .then(response=>{
            //     setloading(false)
            //     navigate(`/audios`)
            // }).catch(err=>{
            //     setloading(false)
            //     try{
            //         seterror(err.response.data.message)
            //     }catch(e){
            //         seterror(err.message)
            //     }
            // })
        }else{
            toast.error(`Please make sure you have provided a title and recorded your message to proceed.`)
        }
    }

    const addAudioElement = (blob) => {
        try{
            toast.success('Recorded!')
            setrecording({
                blob,
                url: URL.createObjectURL(blob)
            })
        }catch(err){
            toast.error(err.message)
        }
    };

    function blobToBase64(blob) {
        return new Promise((resolve, _) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.readAsDataURL(blob);
        });
    }

    const upload = (file)=>{
        setfile(file)
        setcontent(file.base64)
    }
    
    useEffect(() => {
        document.title = 'Send Message to the World | DoGood';
        if(localStorage.getItem(`_auth`) === null){
            navigate(`/login`)
        }
    }, [localStorage])
    

    return (
        <div className='wrapper-style' id='wrapper'>
            {<Header />}
            <PageTitle title='Send Message to World' />
            <Fragment>
            <div className="tf-section flat-create-item flat-edit-profile flat-auctions-details flat-explore flat-auctions">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12">
                            <div className="form-upload-profile post"> 
                                {/* <h3 className="title-one fw-6">Choice Your Cover Image</h3> 
                                <div className="option-profile flex">                                 
                                    <form action="#">
                                        <label className="uploadFile">   
                                            <FileBase64 className="inputfile form-control" multiple={false} onDone={upload} />                                    
                                        </label>
                                    </form> 
                                    <div className="img-box"> 
                                        <div className="media">
                                            <img className="avatar" id="editimg" src={cover} alt="" />   
                                        </div>                                                                                                                                       
                                    </div>
                                </div>  */}
                                
                                <h3 className="title-two fw-6">Message Details</h3> 
                                <form action="#" onSubmit={post} className="form-profile">                     
                                    <div className="form-infor-profile flat-form">
                                        <div className="form-infor flex">
                                            <div className="info-title info-account">                              
                                                <fieldset>
                                                    <p className="title-infor-account">Title</p>
                                                    <input type="text" onInput={e=>settitle(e.target.value)} placeholder="Enter your Message Title" required />
                                                </fieldset>                                                                                                                   
                                            </div>
                                            <div className="info-stock info-social">                                 
                                                <fieldset className="message">
                                                    <p className="title-infor-account">Voice/Audio Message</p>
                                                    <label className="uploadFile">
                                                        <AudioRecorder 
                                                            onRecordingComplete={addAudioElement}
                                                            // downloadOnSavePress={true}
                                                            downloadFileExtension="mp3"
                                                            audioTrackConstraints={{
                                                                noiseSuppression: true,
                                                                echoCancellation: true
                                                            }}
                                                            showVisualizer={true}
                                                        />
                                                        { recording ? <ReactAudioPlayer
                                                            controls
                                                            src={recording?.url}
                                                            style={{
                                                                borderRadius: 10,
                                                                marginLeft: 15,
                                                                width: 300
                                                            }}
                                                        /> : <></> }
                                                    {/* <FileBase64 className="form-control" multiple={false} onDone={upload} /> */}
                                                    </label>                                
                                                </fieldset>
                                            </div>  
                                        </div> 
                                        {/* <fieldset className="message">
                                            <p className="title-infor-account">Description (What is this project about? How can people assist?)</p>
                                            <textarea tabIndex="4" rows="10" onInput={e=>setcontent(e.target.value)} required></textarea>
                                        </fieldset>                                                               */}
                                    </div>

                                    <p className="text-danger">{ error ? error : ""}</p>
                                
                                    <button disabled={loading} className="tf-button-submit mg-t-15" type="submit">
                                        {loading ? 'Please wait...' : 'Send Your Message'}
                                    </button>
                                        
                                </form>              
                            </div> 
                        </div>     
                    </div>           
                </div>
            </div>
            </Fragment>
            <CardModal show={modalShow} onHide={() => setModalShow(false)} />
            {<Flathelp />}
            {<Footer data={dataFooter}/>}
            {<Bottom classNameection='widget-social-footer'/>}
        </div>
    );
}

export default Voice;