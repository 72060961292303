import React , { useState} from 'react';
import PropTypes from 'prop-types';
import ProjectItem from '../project-item';
Project.propTypes = {
    data : PropTypes.array,
};


function Project (props) {
    const {classection} = props;
    const {data} = props;
    const [dataTitle] = useState(
        {   
            title : 'Latest Projects',
            desc: 'Empowering Impact: Unveiling the Latest Waves of Positive Change on Our Community Platform',
        }
    )
    return (
            <div className={`pb-120 style2 ${classection}`}>
                <div className="container">
                    <div className="row">                  
                        <div className="col-lg-12 col-sm-12">
                            <div className="heading-section style center">
                                <h2 className="fw-5">{dataTitle.title}</h2>
                                <p>{dataTitle.desc}</p>
                            </div>
                        </div>
                        {
                            data.slice(0,3).map (item => (
                                <ProjectItem key={item.id} item={item}/>
                            ))
                        }
                    </div>
                </div>
            </div>
    );
}

export default Project;