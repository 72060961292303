import img1 from '../images/box-item/environment.png';
import img2 from '../images/box-item/equality.png';
import img3 from '../images/box-item/community.png';
import img4 from '../images/box-item/tech.png';
import img5 from '../images/box-item/animal.png';
import img6 from '../images/box-item/health.png';
import img7 from '../images/box-item/education.png';
import img8 from '../images/box-item/crisis.png';
import img9 from '../images/box-item/arts.png';

import img10 from '../images/box-item/cetagories-10.jpg';

const dataBanner = [
    {
        id: 1,
        title: 'Environmental Sustainability',
        category: 'environment',
        desc: '50+ projects',
        img: img1,
    },
    {
        id: 2,
        title: 'Social Equality',
        category: 'equality',
        desc: '20+ projects',
        img: img2,
    },
    {
        id: 3,
        title: 'Community Well-being',
        category: 'community',
        desc: '50+ projects',
        img: img3,
    },
    {
        id: 4,
        title: 'Tech for Good',
        category: 'tech',
        desc: '50+ projects',
        img: img4,
    },
    {
        id: 5,
        title: 'Animal Welfare',
        category: 'animal',
        desc: '50+ projects',
        img: img5,
    },
    {
        id: 6,
        title: 'Health and Wellness',
        category: 'health',
        desc: '50+ projects',
        img: img6,
    },
    {
        id: 7,
        title: 'Educational Empowerment',
        category: 'education',
        desc: '50+ projects',
        img: img7,
    },
    {
        id: 8,
        title: 'Crisis Response',
        category: 'crisis',
        desc: '50+ projects',
        img: img8,
    },
    {
        id: 9,
        title: 'Arts and Culture:',
        category: 'arts',
        desc: '50+ projects',
        img: img9,
    },

    {
        id: 10,
        title: 'Global Unity',
        category: 'unity',
        desc: '3 Projects',
        img: img10,
    }
]

export default dataBanner;