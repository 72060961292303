import React, { useState, Fragment } from "react";
import { Link } from "react-router-dom";
import CardModal from "../../CardModal";
import { Tab, Tabs, TabList, TabPanel  } from 'react-tabs';
import Button from '../../button/button-st1';
import { TwitterShareButton, FacebookShareButton, WhatsappShareButton, EmailShareButton } from "react-share";
import icon from '../../.././assets/images/icon/icon-check.svg';
import icon2 from '../../.././assets/images/icon/icon-diamond.svg';

const Profile1 = (props) => {
    const { user, projects, audios } = props;
    const [modalShow, setModalShow] = useState(false);
    const [ data, setdata ] = useState([...projects, ...audios])

    if(!user){
        return null
    }

    return (
        <Fragment>
                <div className="tf-section flat-author-profile flat-explore flat-auctions ">
                    <div className="container">
                        <div className="flat-tabs tab-author">
                            <div className="author-profile flex"> 
                                <div className="feature-profile flex">
                                    <div className="img-box relative">
                                        <img className="avatar" src={user?.photoURL} alt="" />
                                        {/* <img className="check" src={icon} alt="" /> */}
                                    </div>                
                                    <div className="infor">
                                        <h3 className="fs-24 text-color-1">{user?.name}</h3>
                                        <div className="box-price flat-price">                                              
                                            <div className="price flex">
                                                <div className="title-price text-color-1"> {projects.length} Projects</div>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="button-profile flex">
                                    {/* <h3 className="text-color-1">12,03 Followers</h3> */}
                                    <div className="button-follow">
                                        <FacebookShareButton hashtag='#DoGood' content={user ? `Check out ${user?.name}'s profile on DoGood` : `DoGood`} url={`https://dogood.vercel.app/profile/${user?.uid}`}><Button title='Share to Facebook' addclass='sc-button btn-6 style-1 tf-style' /></FacebookShareButton>
                                    </div>
                                    <div className="button-edit">
                                        <WhatsappShareButton content={user ? `Check out ${user?.name}'s profile on DoGood` : `DoGood`} url={`https://dogood.vercel.app/profile/${user?.uid}`}><Button title='Share to WhatsApp' addclass='sc-button btn-5 style-2 tf-style'/></WhatsappShareButton>
                                    </div>
                                </div>
                            </div>
                            <Tabs>
                                <TabList className="menu-tab tab-title">
                                    <Tab><h3>All</h3></Tab>
                                    <Tab><h3>Projects</h3></Tab>
                                    <Tab><h3>Audio Messages</h3></Tab>
                                </TabList>
                                <TabPanel>
                                {
                                    data.slice(4,12).map ((item,index) => (
                                        <div className="sc-card-product sc-card-article blog-article" key={item.id}>
                                            <div className="card-media flat-img">
                                                <Link to="live-auctions-details.html">
                                                    <img src={item.img} alt="img" />
                                                </Link>                                                                                                                                                                                                     
                                            </div>  
                                            <div className="content">
                                                <h6 className="tags">{item.tags}</h6>  
                                                <h3><Link to="live-auctions-details.html">{item.title}</Link></h3> 
                                                <div className="meta-info mb-17">
                                                    <div className="author">
                                                        <div className="avatar">
                                                            <img src={item.imgauthor} alt="" />
                                                            <img className='check' src={item.icon1} alt="DoGood"/>
                                                        </div>
                                                        <div className="info">
                                                            <h5> By<a className="text-color-3" href="author-profile.html">{item.author}</a> </h5>
                                                            <div className="date"> In <span>{item.date}</span></div>                                           
                                                        </div>
                                                    </div>                                  
                                                </div>  
                                                <div className="meta-price flex-one">                                              
                                                    <div className="price flex">
                                                        <img src={item.icon2} alt="DoGood"/>
                                                        <div className="title-price">{item.titleprice}</div>
                                                    </div>
                                                    <div className="button-place-bid">
                                                        <Link to="#" data-toggle="modal" data-target="#popup_bid" className="sc-button btn-3" onClick={() => setModalShow(true)}><span>{item.btn}</span></Link>
                                                    </div>
                                                </div>
                                            </div>    	
                                        </div>  
                                    ))
                                }
                                </TabPanel>
                                <TabPanel>
                                {
                                    projects.map ((item,index) => (
                                        <div className="sc-card-product sc-card-article blog-article" key={item.id}>
                                            <div className="card-media flat-img">
                                                <Link to={`/projects/${item.id}`}>
                                                    <img src={item.poster} alt="" />
                                                </Link>                                                                                                                                                                                                     
                                            </div>  
                                            <div className="content">
                                                <h3><Link to={`/projects/${item.id}`}>{item.title}</Link></h3>  
                                                <div className="meta-price flex-one">                                              
                                                    <div className="button-place-bid">
                                                        <h5>{item.supporters.length} Supporters</h5>
                                                    </div>
                                                </div>
                                            </div>    	
                                        </div>  
                                    ))
                                }
                                </TabPanel>
                                <TabPanel>
                                {
                                    audios.map ((item,index) => (
                                        <div className="sc-card-product sc-card-article blog-article" key={item.id}>
                                            <div className="card-media flat-img">
                                                <Link to={`/audios/${item.id}`}>
                                                    {/* <img src={item.img} alt="" /> */}
                                                </Link>                                                                                                                                                                                                     
                                            </div>  
                                            <div className="content">
                                                <h3><Link to={`/audios/${item.id}`}>{item.title}</Link></h3> 
                                                <div className="meta-info mb-17">
                                                    {/* <div className="author"> */}
                                                        <div className="info">
                                                            <h5><a className="text-color-3">Shared {item.created} ago</a> </h5>
                                                            <div className="date"><p><span>Played {item.played} times</span></p></div>                                           
                                                        </div>
                                                    {/* </div>                                   */}
                                                </div> 
                                            </div>    	
                                        </div>  
                                    ))
                                }
                                </TabPanel>
                            </Tabs>
                            {/* <div className="themesflat-pagination clearfix">
                                <ul>
                                    <li><Link to="#" className="page-numbers">01</Link></li>
                                    <li><Link to="#" className="page-numbers current">02</Link></li>
                                    <li><Link to="#" className="page-numbers">03</Link></li>
                                    <li><Link to="#" className="page-numbers">04</Link></li>
                                </ul>
                            </div> */}
                        </div>
                    </div>
            </div>
        <CardModal show={modalShow} onHide={() => setModalShow(false)} />
        </Fragment>
    );
};


export default Profile1;
