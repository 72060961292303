import React, {useEffect, useState} from 'react';
import '../scss/components/section.scss';
import Header from '../components/header/header.jsx';
import PageTitle from '../components/page-title/page-title-v1/index.jsx';
import Flathelp from '../components/flat-help/index.jsx';
import Footer from '../components/footer/footer-v1/index.jsx';
import dataFooter from '../assets/fake-data/data-footer.js';
import Bottom from '../components/bottom/index.jsx';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { Link, useParams } from "react-router-dom";
import axios from 'axios';
import toast from 'react-hot-toast';
import AudioItem from '../components/audio/audio-item/index.jsx';

function Audios(props) {

    const [audios, setaudios] = useState([])
    const [audio, setaudio] = useState()
    const { category, id } = useParams()

    const load = async ()=>{
        try{

            let response

            if(!category){
                response = (await axios.get(`/audios/status/active`)).data
            }else{
                response = (await axios.get(`/audios/category/${category}`)).data
            }
            setaudios(response.audios)
            setaudio(response.audios.filter(aud=>aud.id === id)[0])
        }catch(err){

        }
    }

    useEffect(() => {
        document.title = 'Messages to the World | DoGood';
        audios.length > 0 ? setaudio(audios.filter(aud=>aud.id === id)[0]) : setaudio(null)
        load()
    }, [ category, id ])
    

    return (
        <div className='wrapper-style' id='wrapper'>
        { audio ? <AudioPlayer
                        autoPlay
                        src={audio.content}
                        style={{
                            position: 'fixed',
                            bottom: 0,
                            zIndex: 3
                        }}
                        header={<div style={{ padding: 10, textAlign: 'center', marginBottom: 15 }}>
                            <h3 className='text-color4'>{audio.title}</h3>
                            <p><small>Now Playing</small></p>
                        </div>}
                        onPlay={e => toast(`Listening to ${audio.title}`)}
                        // other props here
                    />
                    
                    : null }
            {<Header />}
            <PageTitle title='Messages to the World'  />
            <div className="tf-section dark-style2 ">
                <div className="container">
                    <div className="row">
                        { audios.map (item => (
                            <div className="col-lg-3 col-md-4 col-sm-12 m-b-10" id={item.id}>
                                <AudioItem item={item}/>    
                            </div>                        
                        ))}
                        { audios.length > 12 ?
                        <div className="themesflat-pagination clearfix mtop-42 w60">
                            <ul>
                                <li><Link to="#" className="page-numbers">01</Link></li>
                                <li><Link to="#" className="page-numbers current">02</Link></li>
                                <li><Link to="#" className="page-numbers">03</Link></li>
                                <li><Link to="#" className="page-numbers">04</Link></li>
                            </ul>
                        </div> : null }  
                    </div>           
                </div>
            </div>
            {<Flathelp />}
            {<Footer data={dataFooter}/>}
            {<Bottom classection='widget-social-footer'/>}
        </div>
    );
}

export default Audios;