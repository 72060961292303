const authMenu = [
    {
        id: 1,
        name: `Home`,
        links: `/`
    },
    {
        id: 2,
        name: `Dashboard`,
        links: `/dashboard`
    },
    {
        id: 3,
        name: 'Projects',
        links: '#',
        namesub: [
            {
                id: 1,
                sub: 'Projects',
                links: '/projects'
            },
            {
                id: 2,
                sub: 'Messages to the World',
                links: '/audios'
            }
        ]
    },
    {
        id: 4,
        name: 'New Project',
        links: '#',
        namesub: [
            {
                id: 1,
                sub: 'Start Project',
                links: '/start'
            },
            {
                id: 2,
                sub: 'Send Message',
                links: '/voice'
            }
        ]
    },
    {
        id: 5,
        name: `Marketplace`,
        links: `/shop`
    },
    {
        id: 6,
        name: `Logout`,
        links: `/logout`
    }
]

export default authMenu;