import React , { useState , useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Link , NavLink } from 'react-router-dom';
import menus from '../../pages/menu';
import authMenu from '../../pages/authMenu';
import DarkMode from './DarkMode';
import Button from '../button/button-st1';
import './styles.scss';
import logo from '../../assets/images/logo/logo_dark.png'
import logo_light from '../../assets/images/logo/logo.png'


const Header = () => {

    const navigate = useNavigate()
    const [query, setquery] = useState('')
    const [menu, setmenu] = useState(localStorage.getItem(`_auth`) ? authMenu : menus)
    const [scroll, setScroll] = useState(false)
    const [dogood, setdogood] = useState(logo)
    const [theme, settheme] = useState(localStorage.getItem("theme"))
    const [user, setuser] = useState(localStorage.getItem(`_auth`))

    useEffect(() => {
        setuser(localStorage.getItem(`_auth`))        
        localStorage.getItem(`_auth`) ? setmenu(authMenu) : setmenu(menus)
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 300);
            setdogood(null)
            theme === 'is_dark' ? setdogood(logo) : theme === 'light' && window.scrollY > 300 ? setdogood(logo_light) : setdogood(logo)
        });
        return () => {
            setScroll({});
        }
    }, [localStorage]);

    const search = ()=>{
        navigate(`/projects/search/${query}`)
    }

    const logout = (e)=>{
        try{
            // e.preventDefault()
            localStorage.removeItem(`_auth`)
            localStorage.removeItem(`_auth0`)
            navigate(0)
            return false
        }catch(err){
            console.info(err)
        }
    }

    const [menuActive, setMenuActive] = useState(null);

    const handleMenuActive = () => {
        setMenuActive(!menuActive);
      };

    
    const [activeIndex, setActiveIndex] = useState(null);
    const handleDropdown = index => {
        setActiveIndex(index); 
    };    

    return (
        <header id="header_main" className={`header_1 js-header ${scroll ? 'is-fixed' : ''}`}>
        <div className="themes-container">
            <div className="row">
                <div className="col-md-12">                              
                    <div id="site-header-inner"> 
                        <div className="wrap-box flex">
                            <div id="site-logo" className="clearfix">
                                <div id="site-logo-inner">
                                    { dogood ? <NavLink to="/" className="main-logo"><img width="150" src={ dogood } alt="DoGood" /></NavLink> : null }
                                </div>
                            </div>
                            <div className={`mobile-button ${menuActive ? 'active' : ''}`} onClick={handleMenuActive}><span></span></div>
                            <nav id="main-nav" className={`main-nav ${menuActive ? 'active' : ''}`} >
                            <ul id="menu-primary-menu" className="menu">
                                {
                                    menu.map((data,idx) => (
                                        <li key={idx} onClick={()=> handleDropdown(idx)} className={`menu-item ${data.namesub ? 'menu-item-has-children' : ''} ${activeIndex === idx ? 'active' : ''}`} 
                                        
                                        >
                                            <Link onClick={(e)=>{ if(data.links === `/logout`){ e.preventDefault(); logout(); }}} to={data.links}>{data.name}</Link>
                                            {
                                                data.namesub &&
                                                <ul className="sub-menu">
                                                    {
                                                        data.namesub.map((submenu) => (
                                                            <li key={submenu.id} className="menu-item"><NavLink to={submenu.links} onClick={handleDropdown}>{submenu.sub}</NavLink></li>
                                                        ))
                                                    }
                                                </ul>
                                            }
                                            
                                        </li>
                                    ))
                                }
                            </ul>
                        </nav>
                            <div className="flat-search-btn flex">
                                <div className="header-search flat-show-search" >                                   
                                    <div className="top-search">
                                        <form action="" onSubmit={(e)=>e.preventDefault()} method="get" role="search" className="search-form">
                                            <input type="search" id="s" className="search-field style" onInput={e=>setquery(e.target.value)} onSubmit={search} placeholder="Search Here..." name="s" title="Search for" required="" />
                                            <button className="search search-submit" onClick={search} type="submit" title="Search">
                                                <i className="far fa-search"></i>
                                            </button>
                                        </form>
                                    </div>
                                </div>
                                {/* { !user ?
                                <div className="sc-btn-top" id="site-header">
                                    <Button title='Login' addclass='header-slider style wallet fl-button pri-1' path='/login' />
                                </div> :
                                <div className="sc-btn-top" id="site-header">
                                    <Button title='Dashboard' addclass='header-slider style wallet fl-button pri-1' path='/dashboard' />
                                    &nbsp;
                                    <Button onClick={logout} title='Logout' addclass='style-2 btn-5' path='/' />
                                </div> }                                 */}
                            </div>                            
                        </div> 
                    </div>
                </div>
            </div>
        </div>
       <DarkMode />
    </header>
    );
}

export default Header;