
const dataFooter = [
    {
        id: 1,
        title: 'About Us',
        classAction : 'style-1',
        listlink: [
            {
                id: 1,
                text: 'About Us',
                link: '/about'
            },
            {
                id: 2,
                text: 'Projects',
                link: '/projects'
            },
            {
                id: 3,
                text: 'Marketplace',
                link: '/shop'
            },
            {
                id: 4,
                text: 'Join Us',
                link: '/login'
            },
        ]
    },
    {
        id: 2,
        title: 'Company',
        classAction : 'style-2',
        listlink: [
            {
                id: 1,
                text: 'About',
                link: '/about'
            },
            {
                id: 2,
                text: 'Careers',
                link: '/careers'
            },
            {
                id: 3,
                text: 'Affiliates',
                link: '/partners'
            },
            {
                id: 4,
                text: 'Contact',
                link: '/contact'
            },
        ]
    },
    {
        id: 3,
        title: 'Resources',
        classAction : 'style-3',
        listlink: [
            {
                id: 1,
                text: 'Terms of Use',
                link: '/terms'
            },
            {
                id: 2,
                text: 'Privacy Policy',
                link: '/privacy'
            },
            {
                id: 3,
                text: 'Contact',
                link: '/contact'
            },
            {
                id: 4,
                text: 'FAQ',
                link: '/faq'
            },
        ]
    }
]

export default dataFooter;